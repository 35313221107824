import React, { useLayoutEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import Header from "../../../Header";
import { Card } from "primereact/card";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../Course.scss";
import { EditCourse, GetCourseById } from "../../../Services/API/Course";
import { BASE_URL_IMAGE } from "../../../Services/host";

const UpdateCourse = () => {
	const { id } = useParams();
	const [courseData, setCourseData] = useState({
		name: "",
		description: "",
		course_attachments: [],
		author_name: "",
	});
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [removeImageId, setRemoveImageId] = useState(null);
	const [disable, setDisable] = useState(false);
	const navigate = useNavigate();

	useLayoutEffect(() => {
		GetCourseById(id)
			.then((res) => {
				setCourseData(res.data.data);
				if (
					res.data.data.course_attachments &&
					res.data.data.course_attachments.length > 0
				) {
					const attachment = res.data.data.course_attachments[0];
					setImagePreview(`${BASE_URL_IMAGE}${attachment.file_name}`);
					setRemoveImageId(attachment.id);
				}
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setCourseData((prevData) => ({
			...prevData,
			description: data,
		}));
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setImage(file);
			const previewUrl = URL.createObjectURL(file);
			setImagePreview(previewUrl);
		}
	};

	const handleRemoveImage = () => {
		setImage(null);
		setImagePreview(null);
		setRemoveImageId(null);
		document.getElementById("imageInput").value = "";
	};

	const onChange = (e) => {
		setCourseData({ ...courseData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setDisable(true);

		const formData = new FormData();
		formData.append("name", courseData.name || "");
		formData.append("author_name", courseData.author_name || "");
		formData.append("description", courseData.description || "");

		if (image) {
			formData.append("images", image);
		}

		if (removeImageId) {
			formData.append("removeImageIds", removeImageId);
		}

		EditCourse(id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Course updated successfully!");
					navigate(`/editSection/${id}`);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			})
			.finally(() => {
				setDisable(false);
			});
	};

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="Update Course" />
			</Box>
			<Form onSubmit={handleSubmit}>
				<div>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<Card className="left_container">
							<Form.Group className="mb-3">
								<Form.Label>Image:</Form.Label>
								<Form.Control
									type="file"
									accept="image/*"
									id="imageInput"
									onChange={handleImageChange}
								/>
							</Form.Group>

							{imagePreview && (
								<div
									style={{
										border: "1px solid #ccc",
										borderRadius: "5px",
										padding: "10px",
										marginBottom: "10px",
									}}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<img
											src={imagePreview}
											alt="Preview"
											style={{
												height: "80px",
												width: "80px",
												marginRight: "10px",
											}}
										/>
										<DeleteIcon
											fontSize="large"
											color="warning"
											style={{ marginLeft: "auto", cursor: "pointer" }}
											onClick={handleRemoveImage}
										/>
									</div>
								</div>
							)}
						</Card>
						<Card className="right_container">
							<div>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<Form.Label>Course Name:</Form.Label>
											<Form.Control
												name="name"
												defaultValue={courseData.name}
												type="text"
												onChange={onChange}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<Form.Label>Author Name:</Form.Label>
											<Form.Control
												name="author_name"
												defaultValue={courseData.author_name}
												type="text"
												onChange={onChange}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="mb-3">
									<Form.Label>Product Description:</Form.Label>
									<CKEditor
										data={courseData.description}
										editor={ClassicEditor}
										onChange={handleEditorChange}
										config={{
											height: "50%",
										}}
									/>
								</Form.Group>
							</div>
						</Card>
					</div>
					<div className="buttons_divison">
						<Button
							className="save_button"
							sx={{
								background: "#a4a9fc",
								fontSize: "14px",
								fontWeight: "bold",
								padding: "10px",
								marginRight: "0.6rem",
								marginLeft: "10px",
							}}
							onClick={handleSubmit}
							disabled={disable}
						>
							{disable ? "Saving...." : "Save & Next"}
						</Button>
					</div>
				</div>
			</Form>
		</Box>
	);
};

export default UpdateCourse;
