import React, { useContext, useState } from "react";
import "./ForgetPassword.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AdminLogin, ForgetPassword_API, Send_OTP_API } from "../../Services/API/API";
import { AuthContext } from "../../Context/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput,
} from "mdb-react-ui-kit";
import LoginPageImage from "../../../assets/LoginPageImage.png";
import logo from "../../../assets/taxgroup.png";
import { Button } from "react-bootstrap";

const ForgetPassword = () => {
    // const navigate = useNavigate();
    

    const navigateToPrivacyPolicy = () => {
        navigate("/privacypolicy");
    };
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        };
        setOpen(false);
    };

    const [toastMessage, setToastMessage] = useState("");

    const { LoadUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [OTP, setOTP] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [hide, setHide] = useState(false);

    const navigateToSendMail = async (e) => {

        e.preventDefault();

        if(!email || email.length == 0){
            return;
        }
        
        try {
            const formData = new FormData();
            formData.append("email_id", email);
            let result = await Send_OTP_API(formData);
            if (result.status == 200) {
                toast.success(" OTP Send !", {
                    position: "top-right",
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setHide(true);
            };
        } catch (error) {
            console.log("ERROR ", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }

    };

    async function onSubmit(e) {

        e.preventDefault();

        if(OTP?.length == 0 || !OTP){
            alert("Please enter OTP");
            return;
        };
        if(password?.length == 0 || password2?.length == 0){
            alert("Password should match");
            return;
        };
        if(password != password2){
            alert("Password should match");
            return;
        };

        try {
            const formData = new FormData();
            formData.append("otp", OTP);
            formData.append("password", password);
            formData.append("confirm_Password", password2);
            let result = await ForgetPassword_API(formData);

            if (result.status == 200) {
                toast.success(" Password updated successfully !", {
                    position: "top-right",
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }
        } catch (error) {
            console.log("ERROR ", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            // alert();
        }
    };

    return (
        <>
            <MDBContainer className="login_form_container">
                <MDBCard className="login_form_card" style={{ maxHeight: '500px' }}>
                    <MDBRow className="g-0">
                        <MDBCol md="6">
                            <MDBCardImage
                                src={LoginPageImage}
                                alt="login form"
                                style={{ width: '79%', height: '100%', maxHeight: '500px', borderRadius: '80px 0px 0px 80px' }}
                                className="rounded-start"
                            />
                        </MDBCol>

                        <MDBCol md="6" style={{ maxHeight: '500px' }}>
                            <MDBCardBody className="d-flex flex-column">
                                <div className="d-flex flex-row mt-2">
                                    <MDBIcon
                                        fas
                                        icon="cubes fa-3x me-3"
                                        style={{ color: "#ff6219" }}
                                    />
                                    <span className="h1 fw-bold mb-0">
                                        <img src={logo} className="login_logo"></img>
                                    </span>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '-28px', marginRight: '17px' }}>
                                    <h5
                                        className="fw-normal my-3 pb-2 login_form_heading"
                                        style={{ letterSpacing: "1px" }}
                                    >
                                        {!hide ? "Send OTP" :"FORGET PASSWORD"}
                                    </h5>

                                    {!hide && <MDBInput
                                        wrapperClass="mb-2"
                                        label="Email"
                                        id="formControlLg"
                                        type="email"
                                        required
                                        size="lg"
                                        name="email"
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />}
                                   {hide && <>
                                    <MDBInput
                                        wrapperClass="mb-2"
                                        label="OTP"
                                        // id="formControlLg"
                                        required
                                        size="lg"
                                        name="email"
                                        value={OTP}
                                        onChange={(e) => setOTP(e.target.value)}
                                    />
                                    <MDBInput
                                        wrapperClass="mb-2"
                                        label="Password"
                                        // id="formControlLg"
                                        type="password"
                                        size="lg"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <MDBInput
                                        wrapperClass="mb-2"
                                        label="Confirm Password"
                                        // id="formControlLg"
                                        type="password"
                                        size="lg"
                                        name="password"
                                        value={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                    />
                                    </>
}
                                    <button
                                        className="login_form_button mb-4"
                                        onClick={(e) => {
                                            hide ? onSubmit(e) : navigateToSendMail(e);
                                            // handleClick({ vertical: "top", horizontal: "right" });
                                        }}
                                    >
                                        {!hide ? "Send OTP" : "Update Password"}
                                    </button>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme="light"
                                    />

                                    {/* <Link to="/SendMail">Forgot password?</Link> */}
                                    {/* <Button
                                        onClick={navigateToSendMail}
                                        className="admiin_forgetpwd_button"
                                    >
                                        Forget Password?
                                    </Button> */}
                                </div>
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </>
    );
};

export default ForgetPassword;
