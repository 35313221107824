/** @format */

import { Box, Button, Input, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../Header";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { GetAllUser, DeleteUser } from "../../../Services/API/API";
import axios from "axios";
import { BASE_URL } from "../../../Services/host";

import Alert from "../../../Constants/Alert";
import { message } from "antd";

const User = () => {
	const [pageSize, setPageSize] = useState(50);
	const [dataGridHeight, setDataGridHeight] = useState("550px");
	const [userData, setUserData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState();
	const [userId, setUserId] = useState();
	const [userIdToNavigate, setUserIdToNavigate] = useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleClick = (event, value) => {
		setUserIdToNavigate(value);
		setAnchorEl(event.currentTarget);
	};

	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllUser(localStorage.getItem("adminToken"));
			setUserData(result.data.data);
			setUserBackupData(result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");

				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const navigateToAddCategory = () => {
		navigate("/addUser");
	};
	const navigateToViewUser = (event, id) => {
		navigate(`/viewUser/${id}`);
	};

	const updateStatus = async (emailId, newStatus) => {
		try {
			if (newStatus === "REJECTED") {
				// Show a popup/modal to collect the reason
				const rejectionReason = await prompt(
					"Please provide a reason for rejection:"
				);
				if (!rejectionReason) {
					console.log("Rejection reason not provided");
					return;
				}

				console.log(rejectionReason);
				const response = await axios.put(
					`${BASE_URL}/admin/changeUserStatus`,
					{
						email_id: emailId,
						status: newStatus,
						message: rejectionReason, // Include the reason here
					},
					{
						headers: {
							"x-access-token": `${localStorage.getItem("adminToken")}`,
						},
					}
				);

				// Handle success
				console.log(response.data);
				console.log("User status updated successfully");
				window.location.reload(true);
			} else {
				const response = await axios.put(
					`${BASE_URL}/admin/changeUserStatus`,
					{
						email_id: emailId,
						status: newStatus,
					},
					{
						headers: {
							"x-access-token": `${localStorage.getItem("adminToken")}`,
						},
					}
				);

				// Handle success
				console.log(response.data);
				console.log("User status updated successfully");
				window.location.reload(true);
			}
		} catch (error) {
			// Handle error
			console.error("Error updating status:", error);
		}
	};

	const removeUser = (user_id) => {
		setIsModalVisible(false);
		DeleteUser(user_id, localStorage.getItem("adminToken"))
			.then((res) => {
				console.log(res, "res");
				message.success(res?.data?.message);
				getData();
			})

			.catch((error) => {
				console.log(error, "error");
			});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = [];

		for (let i in LIST) {
			if (
				LIST[i]?.name?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
				LIST[i]?.email_id?.toLowerCase()?.includes(searchField?.toLowerCase())
			) {
				searchList.push(LIST[i]);
			}
		}

		setUserData(searchList);
	};

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: 150,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "name",
			headerName: "Name",
			width: 250,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "email_id",
			headerName: "Email Id",
			width: 350,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "status",
			headerName: "Status",
			width: 350,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			renderCell: (cellValues) => {
				const handleStatusChange = async (event) => {
					const newStatus = event.target.value;
					updateStatus(cellValues.row.email_id, newStatus);
					console.log(cellValues);
				};

				return (
					<Select
						native
						value={cellValues.row.status}
						onChange={handleStatusChange}
					>
						<option value="APPROVED">APPROVED</option>
						<option value="REJECTED">REJECTED</option>
					</Select>
				);
			},
		},
		{
			field: "action",
			headerName: "Actions",
			width: "350",
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			renderCell: (cellValues) => {
				return (
					<div>
						<Tooltip title="View">
							<VisibilityIcon
								className="speciality_edit"
								sx={{ marginRight: "15px" }}
								onClick={(event) => navigateToViewUser(event, cellValues.id)}
							/>
						</Tooltip>

						<Alert
							title="User"
							handleDelete={() => removeUser(cellValues.id)}
						/>
					</div>
				);
			},
		},
	];

	return (
		<Box m="20px">
			{loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
				<>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Header title="USER MANAGEMENT" subtitle="View,edit and add User" />
						<Box>
							<Input
								placeholder="Search...."
								onChange={(e) => {
									onSearch(e.target.value);
								}}
								sx={{
									border: "1px solid",
									marginBottom: "15px",
									padding: "5px ",
									width: "200px",
									borderRadius: "5px",
									height: "45px",
								}}
							/>

							<Button
								onClick={navigateToAddCategory}
								className="add_user_button"
								sx={{
									background: "#a4a9fc",
									fontSize: "14px",
									fontWeight: "bold",
									padding: "10px 20px",
									marginRight: "0.6rem",
									marginLeft: "10px",
								}}
							>
								<AddIcon sx={{ mr: "10px" }} />
								ADD NEW USER
							</Button>
						</Box>
					</Box>

					<div
						style={{
							height: dataGridHeight,
							width: "100%",
							// marginLeft: "10%",
							marginTop: "20px",
						}}
					>
						<DataGrid
							rows={userData}
							columns={columns}
							pageSize={pageSize}
							rowHeight={60}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[50, 100, 150]}
							experimentalFeatures={{ newEditingApi: true }}
							onSelectionModelChange={(id) => {
								const selectedIDs = new Set([id]);
								const selectedRowData = userData.filter((row) =>
									selectedIDs.has(row.id.toString())
								);
								setUserId(selectedIDs);
							}}
						/>
					</div>
				</>
			)}
		</Box>
	);
};

export default User;
