import React, { useState, useEffect } from "react";
import { Typography, TextField, Button, Container, Grid } from "@mui/material";
import { CreateCourseIncludeAPI } from "../../Services/API/API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BASE_URL, BASE_URL_IMAGE } from "../../Services/host";
import "./CreateCourseInclude.css";

const CreateCourseInclude = () => {
  const [courseIncludes, setCourseIncludes] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const course_id_here = sessionStorage.getItem("course_id");
    const token = `${localStorage.getItem("adminToken")}`;
    const headers = new Headers();
    headers.append("x-access-token", token);

    fetch(`${BASE_URL}course/getCourseById?course_id=${course_id_here}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setCourseIncludes(data.data.course_include);
        setIsDataFetched(true);
      })
      .catch((error) => {
        console.error("Error fetching course includes:", error);
      });
  }, []);

  const navigate = useNavigate();

  const navigateToCreateCourseSection = () => {
    navigate("/createCourseSection");
  };

  const [courseOutline, setCourseOutline] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  // Handle course outline change
  const handleCourseOutlineChange = (event) => {
    setCourseOutline(event.target.value);
  };

  // Handle file selection for images
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const courseId = sessionStorage.getItem("course_id");
      const formData = new FormData();
      formData.append("course_id", courseId);
      formData.append("course_outline", courseOutline);

      // Perform your API call with formData.
      // Your API should handle the form data accordingly.
      await CreateCourseIncludeAPI(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Course Include created successfully!");
          }
        })
        .catch((err) => {});
    } catch (error) {
      // Handle any errors that occurred during the API request (e.g., show error message)
      console.error("Error creating the course include:", error);
    }

    // Clear the form fields after submission if needed.
    setCourseOutline("");
    setSelectedFile(null);

    window.location.reload(true);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "20px", height: "40vh" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Course Include
      </Typography>
      <form className="form-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Course Outline"
              variant="outlined"
              value={courseOutline}
              onChange={handleCourseOutlineChange}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>

          {isDataFetched ? (
            <div
              style={{
                fontFamily: "Arial",
                backgroundColor: "#F7F7F7",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                marginTop: "10px",
              }}
            >
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#333",
                }}
                variant="h6"
              >
                List of Course Includes:
              </Typography>
              <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
                {courseIncludes.map((include) => (
                  <li
                    key={include.id}
                    style={{
                      fontSize: "18px",
                      margin: "10px 0",
                      color: "#555",
                      display: "flex", // Add flex display for alignment
                      alignItems: "center", // Align items vertically
                    }}
                  >
                    {include.course_outline}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Typography style={{ fontSize: "18px", color: "#777" }}>
              Loading course includes...
            </Typography>
          )}
        </Grid>
      </form>
      <Grid item xs={12}>
        {/* Add the button for "Create Sections" here */}
        <Button
          className="create-sections-button"
          variant="contained"
          color="secondary"
          endIcon={<NavigateNextIcon />}
          onClick={() => {
            navigateToCreateCourseSection();
          }}
        >
          Create Sections
        </Button>
      </Grid>
    </Container>
  );
};

export default CreateCourseInclude;
