import React from "react";
import "./Error401.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import AccessImage from "../../../assets/access-denied-5627546-4694106.1a0f9e43d2bf41f17f7a.webp";

const Error401 = () => {
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate("/");
  };

  return (
    <div className="main_container">
      <img src={AccessImage} alt="Access Denied" className="error_image" />
      <div className="main_box">
        <div className="error_heading">
          You are not allowed to access this page
        </div>
        <p className="text-muted my-4">
          You have been denied access to this page. Please check your
          permissions.
        </p>
      </div>
      <div className="back_button">
        <Button
        className="back_dashboard"
        variant="primary"
        type="submit"
        onClick={(e) => {
          navigateToDashboard(e);
        }}
     
        >Back To Dashboard
        </Button>
      </div>
    </div>
  );
};
export default Error401;

