/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { GetCalculatorById } from "../../Services/API/Calculator";
import { BASE_URL_IMAGE } from "../../Services/host";
import { Card } from "primereact/card";
import Header from "../../Header";

const ViewCalculator = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [proposalData, setProposalData] = useState([]);

	const navigateToCalculator = () => {
		navigate("/calculators");
	};

	//get role By ID
	useLayoutEffect(() => {
		GetCalculatorById(id)
			.then((res) => {
				setProposalData(res.data.data);
				console.log(res.data.data, "data");
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);
	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="VIEW CALCULATOR" subtitle="View Calculator" />
			</Box>

			<Card>
				<div>
					<Form className="add_calc_form">
						<Row>
							<Form.Group className="mb-3">
								<Form.Label>Calculator Name:</Form.Label>
								<Form.Control
									name="name"
									defaultValue={proposalData?.name}
									type="text"
									rows={9}
									disabled
								/>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group className="mb-3">
								<Form.Label>Image:</Form.Label>
								<br />
								{proposalData && proposalData.file_name !== null ? (
									<img
										src={`${BASE_URL_IMAGE}${proposalData.file_name}`}
										alt="Calculator"
										className="category-icon-preview"
										style={{ height: "100px", width: "100px" }}
									/>
								) : (
									<span>No Image Available</span>
								)}
							</Form.Group>
						</Row>

						<Row>
							<Form.Group className="mb-3">
								<Form.Label>Redirection Url:</Form.Label>
								<Form.Control
									name="name"
									defaultValue={proposalData?.redirection_url}
									type="text"
									rows={9}
									disabled
								/>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group className="mb-3">
								<Form.Label>Description:</Form.Label>
								<div
									style={{
										backgroundColor: "#EBECF2",
										borderRadius: "5px",
										padding: "10px",
										border: "1px solid #ced4da ",
									}}
									dangerouslySetInnerHTML={{
										__html: proposalData?.description,
									}}
								/>
							</Form.Group>
						</Row>

						<div>
							<Button
								icon="pi pi-arrow-left"
								severity="secondary"
								onClick={(e) => {
									navigateToCalculator();
								}}
								style={{
									borderRadius: "5px",
									height: "40px",
									marginTop: "30px",
								}}
							>
								<span style={{ marginLeft: "5px" }}> Return to Calculator</span>
							</Button>
						</div>
					</Form>
				</div>
			</Card>
		</Box>
	);
};

export default ViewCalculator;
