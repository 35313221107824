import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { CreateCourseSectionAPI } from "../../Services/API/API";
import { toast } from "react-toastify";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BASE_URL } from "../../Services/host";
import { useNavigate } from "react-router-dom";

const MyComponent = () => {
  const [courseSections, setCourseSections] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const course_id_here = sessionStorage.getItem("course_id");
    const token = `${localStorage.getItem("adminToken")}`;
    const headers = new Headers();
    headers.append("x-access-token", token);

    fetch(`${BASE_URL}course/getCourseById?course_id=${course_id_here}`, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setCourseSections(data.data.course_section);
        setIsDataFetched(true);
      })
      .catch((error) => {
        console.error("Error fetching course includes:", error);
      });
  }, []);

  const navigate = useNavigate();

  const navigateToCreateSectionDetails = () => {
    navigate("/createSectionDetails");
    window.location.reload(true);
  };

  const [courseDuration, setCourseDuration] = useState({
    hours: 0,
    minutes: 0,
  });

  const handleDurationChange = (event) => {
    const { name, value } = event.target;
    setCourseDuration({ ...courseDuration, [name]: value });
  };

  const [sectionHeading, setSectionHeading] = useState("");
  const [lectureCount, setLectureCount] = useState("");
  const [totalDuration, setTotalDuration] = useState("");

  // Retrieve course_id from session storage
  const course_id = sessionStorage.getItem("course_id");

  // Retrieve sectionId from session storage or initialize to 1 if it doesn't exist
  const initialSectionId = Number(sessionStorage.getItem("sectionId")) || 1;
  const [sectionId, setSectionId] = useState(initialSectionId);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Your API call logic here with the form values
    try {
      const courseDurationString = `${courseDuration.hours} Hours ${courseDuration.minutes} Minutes`;
      console.log(courseDurationString);

      const formData = {
        course_id: course_id,
        section_id: sectionId,
        section_heading: sectionHeading,
        lecture_count: lectureCount,
        total_duration: courseDurationString,
      };

      // Make the API call to create the course section
      await CreateCourseSectionAPI(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Course Section created successfully!");
          }
        })
        .catch((err) => {});

      // Once the section is successfully created, you can increment the sectionId
      const nextSectionId = sectionId + 1;
      setSectionId(nextSectionId);

      // Update the sectionId in session storage
      sessionStorage.setItem("sectionId", nextSectionId);

      // Clear other form fields or do whatever else is necessary after successful submission
      setSectionHeading("");
      setLectureCount(0);
      setTotalDuration(0);
    } catch (error) {
      // Handle any errors that occur during API call, e.g., show an error message to the user
      console.error("Error creating course section:", error.message);
    }
    window.location.reload(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "120px",
        height: "89vh",
        position: "relative",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          fontSize: "37px",
          fontWeight: "bold",
          marginTop: "-80px",
        }}
      >
        Here, you can create segregated sections for your course
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "75px",
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            maxWidth: "450px",
            width: "100%",
            padding: "20px",
            marginTop: "-40px",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Section Heading"
                value={sectionHeading}
                onChange={(e) => setSectionHeading(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Lecture Count"
                value={lectureCount}
                onChange={(e) => setLectureCount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  gap: "10px",
                }}
              >
                <TextField
                  type="number"
                  label="Hours"
                  name="hours"
                  value={courseDuration.hours}
                  onChange={handleDurationChange}
                  inputProps={{ min: 0, max: 1000 }}
                />{" "}
                hrs{" "}
                <TextField
                  type="number"
                  label="Minutes"
                  name="minutes"
                  value={courseDuration.minutes}
                  onChange={handleDurationChange}
                  inputProps={{ min: 0, max: 59 }}
                />{" "}
                mins
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Create Section
              </Button>
            </Grid>
          </Grid>
        </form>
        {isDataFetched ? (
          <div
            style={{
              fontFamily: "Arial",
              backgroundColor: "#F7F7F7",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              marginTop: "-60px",
            }}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                marginBottom: "10px",
                color: "#333",
              }}
              variant="h6"
            >
              List of Course Sections:
            </Typography>
            <ul style={{ listStyleType: "none", padding: "0", margin: "0" }}>
              {courseSections.map((include) => (
                <li
                  key={include.id}
                  style={{
                    fontSize: "18px",
                    margin: "10px 0",
                    color: "#555",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {include.section_heading}
                  {"  -  "}

                  {include.total_duration}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <Typography style={{ fontSize: "18px", color: "#777" }}>
            Loading course sections...
          </Typography>
        )}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          right: "60px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          endIcon={<NavigateNextIcon />}
          onClick={() => {
            navigateToCreateSectionDetails();
          }}
        >
          Create Lectures
        </Button>
      </div>
    </div>
  );
};

export default MyComponent;
