import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { Button as MUIButton } from "@mui/material";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import { BASE_URL, BASE_URL_IMAGE } from "../../Services/host";
import { CreateCourseIncludeAPI } from "../../Services/API/API";
import { toast } from "react-toastify";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import Header from "../../Header";

const CourseDetails = () => {
  let { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedItemId, setEditedItemId] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [editedCourseOutline, setEditedCourseOutline] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [courseOutline, setCourseOutline] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const navigate = useNavigate();

  const fetchCourseData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/course/getCourseById?course_id=${id}`,
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      );
      setCourseData(response.data.data);
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, [id]);

  const handleImageChange = (event, itemId) => {
    setSelectedImageFile(event.target.files[0]);
  };

  const handleCourseOutlineChange = (event) => {
    setEditedCourseOutline(event.target.value);
  };

  const handleEditClick = (itemId, courseOutline) => {
    setEditMode(true);
    setEditedItemId(itemId);
    setEditedCourseOutline(courseOutline);
  };

  // Function to toggle the form's visibility
  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const handleConfirmEdit = async (itemId) => {
    try {
      // Update image using PUT request
      console.log(itemId);

      // Create a FormData object to include both image and course outline
      const formData = new FormData();

      if (selectedImageFile) {
        formData.append("images", selectedImageFile);
      }

      if (editedCourseOutline) {
        formData.append("course_outline", editedCourseOutline);
      }

      // Send a PUT request with the FormData
      await axios.patch(
        `${BASE_URL}/admin/updateCourseInclude/${itemId}`,
        formData,
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      );

      // Refresh the data after successful update
      fetchCourseData();
      setEditMode(false);
      setEditedItemId(null);
      setSelectedImageFile(null);
      setEditedCourseOutline("");
    } catch (error) {
      console.error("Error updating course data:", error);
    }
  };

  // Function to handle the delete action
  const handleDelete = async (itemId, courseOutline) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this Course Include Item: ${courseOutline}?`
    );

    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}/admin/deleteCourseInclude/${itemId}`, {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        });

        // Refresh the data after successful delete
        fetchCourseData();
      } catch (error) {
        console.error("Error deleting course include:", error);
      }
    }
  };

  const navigateToEditCourseSection = (id) => {
    navigate(`/editCourseSection/${id}`);
  };

  // Code For New Course Include Creation
  // Handle course outline change
  const handleNewCourseOutlineChange = (event) => {
    setCourseOutline(event.target.value);
  };

  // Handle file selection for images
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = (event, id) => {
    event.preventDefault();
    try {
      const courseId = id;
      const formData = new FormData();
      formData.append("course_id", courseId);
      formData.append("course_outline", courseOutline);
      formData.append("images", selectedFile);

      // Perform your API call with formData.
      // Your API should handle the form data accordingly.
      CreateCourseIncludeAPI(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Course Include created successfully!");
            window.location.reload(true);
          }
        })
        .catch((err) => {});
    } catch (error) {
      // Handle any errors that occurred during the API request (e.g., show error message)
      console.error("Error creating the course include:", error);
    }

    // Clear the form fields after submission if needed.
    setCourseOutline("");
    setSelectedFile(null);
  };

  if (!courseData) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="EDIT COURSE INCLUDE" subtitle="Edit Course Include" />

        <div style={{ display: "flex", gap: "28px" }}>
          <Button
            variant="warning"
            className="edit_button"
            onClick={() => {
              navigateToEditCourseSection(id);
            }}
          >
            Edit Sections
          </Button>

          <Button
            variant="warning"
            className="edit_button"
            onClick={() => {
              // Handle the action for editing section details (lectures)
            }}
          >
            Edit Section Details (Lectures)
          </Button>
        </div>
      </Box>

      <div>
        <Typography variant="h4">Course : {courseData.course_name}</Typography>
        <Card>
          <CardContent>
            <Typography variant="subtitle1">Course Includes:</Typography>
            <List>
              {courseData.course_include.map((include) => (
                <div key={include.id}>
                  <ListItem>
                    {editMode && editedItemId === include.id ? (
                      <div style={{ display: "flex", gap: "18px" }}>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) =>
                            handleImageChange(event, include.id)
                          }
                        />
                        <input
                          type="text"
                          value={editedCourseOutline}
                          onChange={handleCourseOutlineChange}
                        />
                        <Button onClick={() => handleConfirmEdit(include.id)}>
                          Confirm
                        </Button>
                        <Button onClick={() => setEditMode(false)}>
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <>
                        <img
                          src={`${BASE_URL_IMAGE}${include?.icons}`}
                          alt={`Icon for ${include.course_outline}`}
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                        <ListItemText primary={include.course_outline} />
                        <Button
                          onClick={() =>
                            handleEditClick(include.id, include.course_outline)
                          }
                          style={{ marginRight: "17px" }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() =>
                            handleDelete(include.id, include.course_outline)
                          }
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            marginTop: "23px",
          }}
        >
          <Button onClick={toggleFormVisibility}>
            {showForm ? "Hide Form" : "Add A New Course Include"}
          </Button>
        </div>
        {showForm && (
          <form className="form-container" style={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Course Outline"
                  variant="outlined"
                  value={courseOutline}
                  onChange={handleNewCourseOutlineChange}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  type="file"
                  id="icon-image"
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
                <label htmlFor="icon-image">
                  <MUIButton variant="contained" component="span">
                    Upload Icon Image
                  </MUIButton>
                  {selectedFile && selectedFile.name}
                </label>
              </Grid>
              <Grid item xs={12}>
                <MUIButton
                  variant="contained"
                  color="primary"
                  onClick={(event) => handleSubmit(event, id)}
                >
                  Submit
                </MUIButton>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Box>
  );
};

export default CourseDetails;
