/** @format */

import { Box, Button, Input } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../Header";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { GetAllCourses, DeleteCourse } from "../../Services/API/Course";
import { message } from "antd";
import Alert from "../../Constants/Alert";

const ViewCourses = () => {
	const [pageSize, setPageSize] = useState(50);
	const [dataGridHeight, setDataGridHeight] = useState("550px");
	const [coursesData, setCoursesData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState();
	const [userId, setUserId] = useState();
	const [userIdToNavigate, setUserIdToNavigate] = useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleClick = (event, value) => {
		setUserIdToNavigate(value);
		setAnchorEl(event.currentTarget);
	};

	//get all admin user api implementation

	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllCourses(localStorage.getItem("adminToken"));
			setCoursesData(result.data.data);
			setUserBackupData(result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");

				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const navigateToCreateCourse = () => {
		navigate("/createCourse");
		window.location.reload(true);
	};

	const navigateToViewCourse = (event, id) => {
		navigate(`/viewCourse/${id}`);
	};

	const navigateToEditCourse = (event, id) => {
		navigate(`/editCourse/${id}`);
	};
	const removeCourse = (user_id) => {
		setIsModalVisible(false);
		DeleteCourse(user_id, localStorage.getItem("adminToken"))
			.then((res) => {
				console.log(res, "res");
				message.success(res?.data?.message);
				getData();
			})

			.catch((error) => {
				console.log(error, "error");
			});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = [];

		for (let i in LIST) {
			if (LIST[i]?.name?.toLowerCase()?.includes(searchField?.toLowerCase())) {
				searchList.push(LIST[i]);
			}
		}

		setCoursesData(searchList);
	};

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: 150,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "name",
			headerName: "Name",
			width: 350,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "author_name",
			headerName: "Author Name",
			width: 350,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},

		{
			field: "action",
			headerName: "Actions",
			width: "350",
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			renderCell: (cellValues) => {
				return (
					<div>
						<Tooltip title="View">
							<VisibilityIcon
								className="speciality_edit"
								sx={{ marginRight: "15px" }}
								onClick={(event) => navigateToViewCourse(event, cellValues.id)}
							/>
						</Tooltip>
						<Tooltip title="Edit">
							<ModeEditIcon
								className="speciality_edit"
								sx={{ marginRight: "15px" }}
								onClick={(event) => navigateToEditCourse(event, cellValues.id)}
							/>
						</Tooltip>
						<Alert
							title="Course"
							handleDelete={() => removeCourse(cellValues.id)}
						/>
					</div>
				);
			},
		},
	];

	return (
		<Box m="20px">
			{loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
			<>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Header title="Course Management" subtitle="View & Delete Course" />
					<Box>
						<Input
							placeholder="Search...."
							onChange={(e) => {
								onSearch(e.target.value);
							}}
							sx={{
								border: "1px solid",
								marginBottom: "15px",
								padding: "5px ",
								width: "200px",
								borderRadius: "5px",
								height: "45px",
							}}
						/>
						<Button
							onClick={navigateToCreateCourse}
							className="add_user_button"
							sx={{
								background: "#a4a9fc",
								fontSize: "14px",
								fontWeight: "bold",
								padding: "10px 20px",
								marginRight: "0.6rem",
								marginLeft: "10px",
							}}
						>
							<AddIcon sx={{ mr: "10px" }} />
							Add Course
						</Button>
					</Box>
				</Box>
				<div
					style={{
						height: dataGridHeight,
						width: "100%",
						// marginLeft: "10%",
						marginTop: "20px",
					}}
				>
					<DataGrid
						rows={coursesData}
						columns={columns}
						pageSize={pageSize}
						rowHeight={60}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						rowsPerPageOptions={[50, 100, 150]}
						experimentalFeatures={{ newEditingApi: true }}
						onSelectionModelChange={(id) => {
							const selectedIDs = new Set([id]);
							const selectedRowData = coursesData.filter((row) =>
								selectedIDs.has(row.id.toString())
							);
							setUserId(selectedIDs);
						}}
					/>
				</div>
			</>
				)}
		</Box>
	);
};

export default ViewCourses;
