
import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./User.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../Header";
import { message } from "antd";
import { CreateUser } from "../../../Services/API/API";
import { Card } from "primereact/card";

const AddUser = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name) {
      message.error("Please enter name ");
      return;
    }

    if (!email) {
      message.error("Please enter email");
      return;
    }

    try {
      const response = await CreateUser({
        email_id: email,
        name: name,
      });

      if (response.status === 200) {
        message.success("User added successfully");
        setTimeout(() => {
          navigate("/user");
        }, 1000);
      
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Email already exists");
      } else if (error.response.status === 401) {
        message.error("Token expired");
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        message.error("Something went wrong");
      }
    }
  };

  const navigateToMentee = () => {
    navigate("/user");
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CREATE USER" subtitle="Create a New User" />
      </Box>
      <Card className="user_add_form">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <div className="button_container">
            <Button
              className="add_new_button1"
              variant="primary"
              type="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </Button>

            <Button
              variant="dark"
              className="add_new_button"
              type="submit"
              onClick={() => navigateToMentee()}
            >
              Go Back
            </Button>
          </div>
        </Form>
      </Card>
    </Box>
  );
};

export default AddUser;
