/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Accordion, Container, ListGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaPlus, FaEdit, FaTrashAlt } from "react-icons/fa";
import { message } from "antd";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { GiCheckMark } from "react-icons/gi";
import "../Course.scss";
import {
	AddSection,
	GetCourseById,
	DeleteSection,
	EditSection,
	AddLecture,
} from "../../../Services/API/Course";
import LectureForm from "../LectureForm";

const UpdateSection = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [courseData, setCourseData] = useState(null);
	const [disable, setDisable] = useState(false);
	const [show, setShow] = useState(false);
	const [name, setName] = useState("");
	const [selectedSection, setSelectedSection] = useState(null);
	const [selectedLecture, setSelectedLecture] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false); // Added state for delete modal
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorLecture, setAnchorLecture] = React.useState(null);
	const [selectedSectionId, setSelectedSectionId] = useState(null);
	const [showLectureForm, setShowLectureForm] = useState(false);
	const [course, setCourse] = useState({});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLectureClick = (event) => {
		setAnchorLecture(event.currentTarget);
	};

	const handleLectureCloseMenu = () => {
		setAnchorLecture(null);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const getCourseData = () => {
		GetCourseById(id)
			.then((res) => {
				setCourseData(res.data.data);
				console.log("GetCourseById=>", res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	};

	useLayoutEffect(() => {
		getCourseData();
	}, [id]);

	const handleClose = () => setShow(false);
	const handleShow = () => {
		setName(""); // Reset the name state to clear previous entry
		setShow(true);
	};

	const handleAddSection = async (event) => {
		event.preventDefault();
		setDisable(true);
		if (!name) {
			message.error("Please enter Section Name");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("course_id", id);
			formData.append("name", name);

			const response = await AddSection(formData);

			if (response.status === 201) {
				message.success("Section added successfully!");
				handleClose(); // Close modal after successful addition
				getCourseData(); // Refresh course data after addition
			}
			setDisable(false);
		} catch (error) {
			if (error.response.status === 401) {
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	const handleEditSectionClick = (section) => {
		handleCloseMenu(); // Close the menu
		setSelectedSection(section);
		setShowEditModal(true);
	};

	const handleEditSection = (e) => {
		if (!selectedSection || !selectedSection.id) {
			// Handle case where no section is selected or it has no ID
			console.error("No section selected or invalid section ID");
			return;
		}

		const formData = new FormData();
		formData.append("name", selectedSection.name); // Use selectedSection name

		EditSection(selectedSection.id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Section edited successfully!");
					getCourseData();
					setShowEditModal(false);
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			});
	};

	const handleSectionDeleteClick = (section) => {
		handleCloseMenu(); // Close the menu
		setSelectedSection(section);
		setShowDeleteModal(true);
	};

	const handleSectionDelete = (section_id) => {
		DeleteSection(section_id, localStorage.getItem("adminToken"))
			.then((res) => {
				console.log(res, "res");
				message.success(res?.data?.message);
				getCourseData();
				setShowDeleteModal(false); // Close delete modal after successful deletion
			})
			.catch((error) => {
				console.log(error, "error");
				setShowDeleteModal(false); // Close delete modal even on error
			});
	};

	//lecture functions here

	const handleAddLecture = (sectionId) => {
		setSelectedSectionId(sectionId);
		setShowLectureForm(true);
	};

	// const handleLectureSubmit = (courseId, sectionId) => {
	// 	// Logic to save lecture
	// 	setShowLectureForm(false); // Close the lecture form after saving
	// };

	const handleLectureSubmit = async (formData) => {
		setDisable(true);
		try {
			const data = new FormData();
			data.append("course_id", formData.courseId);
			data.append("section_id", formData.sectionId);
			data.append("name", formData.lectureName);
			data.append("duration", formData.duration);
			data.append("type", formData.lectureType);
			data.append("video_link", formData.videoLink);
			data.append("text_content", formData.textContent);
			if (formData.document) {
				for (let i = 0; i < formData.document.length; i++) {
					data.append("docs", formData.document[i]);
				}
			}

			const response = await AddLecture(data);
			console.log("AddLecture response:", response); // Log the response

			if (response && response.status === 201) {
				message.success("Lecture added successfully!");
				setShowLectureForm(false);

				// Update course data if needed
				const updatedCourse = { ...course };
				const sectionIndex = updatedCourse.course_sections.findIndex(
					(sec) => sec.id === formData.sectionId
				);
				updatedCourse.course_sections[sectionIndex].section_lectures.push(
					response.data.data
				);
				setCourse(updatedCourse);
			} else {
				message.error("Failed to add lecture. Please try again.");
			}
		} catch (error) {
			console.error("Error in handleLectureSubmit:", error); // Log the error
			if (error.response && error.response.status === 401) {
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			}
		} finally {
			setDisable(false);
		}
	};

	const handleCancel = () => {
		setShowLectureForm(false); // Close the lecture form on cancel
	};

	const navigateToCourse = () => {
		navigate("/courses");
	};

	console.log(courseData, "courseData");
	return (
		<div className="CourseLearn">
			<Container>
				<div className="main">
					<div className="course_list">
						<h3>{courseData?.name}</h3>
						<div className="List_scroll">
							{courseData?.course_sections?.length > 0 ? (
								<Accordion>
									{courseData?.course_sections?.map((item, index) => {
										return (
											<Accordion.Item eventKey={index} key={index}>
												<Accordion.Header>
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															justifyContent: "space-between",
															width: "100%",
														}}
													>
														<div className="width_full">
															<div className="section_name">
																<h5>Section {index + 1}</h5>
															</div>
															<h6>{item?.name}</h6>
														</div>

														<div style={{ marginLeft: "auto" }}>
															<IconButton
																aria-label="more"
																aria-controls="long-menu"
																aria-haspopup="true"
																onClick={handleClick}
															>
																<HiDotsHorizontal
																	style={{
																		cursor: "pointer",
																		fontSize: "25px",
																	}}
																	onClick={(e) => {
																		e.stopPropagation(); // Prevent event propagation
																		handleClick(e);
																		setSelectedSection(item);
																	}}
																/>
															</IconButton>
															{selectedSection &&
																selectedSection.id === item.id && (
																	<Menu
																		id="long-menu"
																		anchorEl={anchorEl}
																		keepMounted
																		open={Boolean(anchorEl)}
																		onClose={handleCloseMenu}
																	>
																		<MenuItem
																			onClick={() =>
																				handleEditSectionClick(item)
																			}
																		>
																			<FaEdit style={{ marginRight: "5px" }} />
																			Edit Section
																		</MenuItem>
																		<MenuItem
																			onClick={() =>
																				handleSectionDeleteClick(item)
																			}
																		>
																			<span style={{ color: "red" }}>
																				<FaTrashAlt
																					style={{ marginRight: "5px" }}
																				/>
																				Delete Section
																			</span>
																		</MenuItem>
																	</Menu>
																)}
														</div>
													</div>
												</Accordion.Header>
												<Accordion.Body>
													<ListGroup>
														{item?.section_lectures?.map((lecture, index) => {
															return (
																<ListGroup.Item
																	key={index}
																	style={{
																		display: "flex",
																		flexDirection: "row",
																		justifyContent: "space-between",
																	}}
																>
																	<div className="section_name">
																		<div>{lecture.name}</div>
																	</div>
																	<IconButton
																		aria-label="more"
																		aria-controls="long-menu"
																		aria-haspopup="true"
																		onClick={handleLectureClick}
																	>
																		<HiDotsHorizontal
																			style={{
																				cursor: "pointer",
																				fontSize: "20px",
																			}}
																			onClick={(e) => {
																				e.stopPropagation(); // Prevent event propagation
																				handleLectureClick(e);
																				setSelectedLecture(item);
																			}}
																		/>
																	</IconButton>
																	{selectedLecture &&
																		selectedLecture.id === item.id && (
																			<Menu
																				id="long-menu"
																				anchorEl={anchorEl}
																				keepMounted
																				open={Boolean(anchorEl)}
																				onClose={handleCloseMenu}
																			>
																				<MenuItem
																				// onClick={() =>
																				// 	handleEditSectionClick(item)
																				// }
																				>
																					<FaEdit
																						style={{ marginRight: "5px" }}
																					/>
																					Edit Lecture
																				</MenuItem>
																				<MenuItem
																				// onClick={() =>
																				// 	handleSectionDeleteClick(item)
																				// }
																				>
																					<span style={{ color: "red" }}>
																						<FaTrashAlt
																							style={{ marginRight: "5px" }}
																						/>
																						Delete Lecture
																					</span>
																				</MenuItem>
																			</Menu>
																		)}
																</ListGroup.Item>
															);
														})}
													</ListGroup>
													<Button
														startIcon={<FaPlus />}
														variant="outlined"
														onClick={() => handleAddLecture(item.id)}
														sx={{
															background: "white",
															fontSize: "14px",
															fontWeight: "bold",
															padding: "10px",
															margin: "20px",
															border: "none",
															width: "91%",
															borderRadius: "0",
														}}
													>
														Add New Lecture
													</Button>
												</Accordion.Body>
											</Accordion.Item>
										);
									})}
								</Accordion>
							) : (
								<div className="no-lecture-container">
									<p>No Section Added</p>
								</div>
							)}
							<Button
								startIcon={<FaPlus />}
								variant="outlined"
								onClick={handleShow}
								sx={{
									background: "#a4a9fc",
									fontSize: "14px",
									fontWeight: "bold",
									padding: "10px",
									marginRight: "0.6rem",
									marginLeft: "10px",
									border: "none",
									width: "97%",
									borderRadius: "0",
								}}
							>
								Add New Section
							</Button>

							{/* add new section modal */}
							<Modal
								show={show}
								onHide={handleClose}
								aria-labelledby="contained-modal-title-vcenter"
								centered
							>
								<Modal.Header closeButton>
									<Modal.Title>Add Section</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Form>
										<Form.Group className="mb-3">
											<Form.Label>Section Name:</Form.Label>
											<Form.Control
												type="text"
												required
												placeholder="Please enter your Section Name!"
												value={name}
												onChange={(e) => setName(e.target.value)}
												className="new_form_control"
											/>
										</Form.Group>
									</Form>
								</Modal.Body>
								<Modal.Footer>
									<Button
										onClick={handleClose}
										sx={{
											width: "100px",
											color: "white",
											background: "#7F7F7F",
											fontSize: "14px",
											fontWeight: "bold",
											padding: "10px",
											marginRight: "0.6rem",
											marginLeft: "10px",
										}}
									>
										Close
									</Button>
									<Button
										className="save_section_button"
										sx={{
											background: "#a4a9fc",
											fontSize: "14px",
											fontWeight: "bold",
											padding: "10px",
											marginRight: "0.6rem",
											marginLeft: "10px",
										}}
										onClick={handleAddSection}
										disabled={disable}
									>
										{disable ? "Saving...." : "Save"}
									</Button>
								</Modal.Footer>
							</Modal>

							{/* Edit Section Modal */}
							<Modal
								show={showEditModal}
								onHide={() => setShowEditModal(false)}
								aria-labelledby="contained-modal-title-vcenter"
								centered
							>
								<Modal.Header closeButton>
									<Modal.Title>Edit Section</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									{/* Edit form */}
									{selectedSection && (
										<Form>
											<Form.Group controlId="sectionName">
												<Form.Label>Section Name</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter section name"
													value={selectedSection.name}
													// Update selectedSection.name
													onChange={(e) =>
														setSelectedSection({
															...selectedSection,
															name: e.target.value,
														})
													}
												/>
											</Form.Group>
										</Form>
									)}
								</Modal.Body>
								<Modal.Footer>
									<Button
										onClick={() => setShowEditModal(false)}
										sx={{
											width: "100px",
											color: "white",
											background: "#7F7F7F",
											fontSize: "14px",
											fontWeight: "bold",
											padding: "10px",
											marginRight: "0.6rem",
											marginLeft: "10px",
											cursor: "cell",
										}}
									>
										Close
									</Button>

									<Button
										className="save_section_button"
										sx={{
											background: "#a4a9fc",
											fontSize: "14px",
											fontWeight: "bold",
											padding: "10px",
											marginRight: "0.6rem",
											marginLeft: "10px",
										}}
										onClick={() => handleEditSection(selectedSection)}
										disabled={disable}
									>
										{disable ? "Saving...." : "Save"}
									</Button>
								</Modal.Footer>
							</Modal>

							{/* Delete Section Modal */}
							<Modal
								show={showDeleteModal}
								onHide={() => setShowDeleteModal(false)}
								aria-labelledby="contained-modal-title-vcenter"
								centered
							>
								<Modal.Header closeButton>
									<Modal.Title>Delete Section</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<p>Are you sure you want to delete this Section?</p>
								</Modal.Body>
								<Modal.Footer>
									<Button
										onClick={() => setShowDeleteModal(false)}
										sx={{
											width: "100px",
											color: "white",
											background: "#7F7F7F",
											fontSize: "14px",
											fontWeight: "bold",
											padding: "10px",
											marginRight: "0.6rem",
											marginLeft: "10px",
											cursor: "cell",
										}}
									>
										Cancel
									</Button>
									<Button
										className="delete_section_button"
										sx={{
											background: "#f44336",
											color: "white",
											fontSize: "14px",
											fontWeight: "bold",
											padding: "10px",
											marginRight: "0.6rem",
											marginLeft: "10px",
										}}
										onClick={() => handleSectionDelete(selectedSection.id)}
									>
										Delete
									</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</div>
					{/* <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="no-lecture-container">
              <p>No Lectures Added</p>
            </div>
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={navigateToCourse}
                startIcon={<GiCheckMark style={{ marginRight: "0px" }} />}
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px",
                  marginLeft: "10px",
                  border: "none",
                  borderRadius: "4px",
                  left: "535px",
                }}
              >
                Complete
              </Button>
            </div>
          </div> */}

					{showLectureForm ? (
						<LectureForm
							courseId={id}
							sectionId={selectedSectionId}
							handleLectureSubmit={handleLectureSubmit}
							onCancel={handleCancel}
						/>
					) : (
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div className="no-lecture-container">
								<p>No Lectures Added</p>
							</div>
							<div>
								<Button
									variant="contained"
									color="success"
									onClick={navigateToCourse}
									startIcon={<GiCheckMark style={{ marginRight: "0px" }} />}
									sx={{
										fontSize: "14px",
										fontWeight: "bold",
										padding: "10px",
										marginLeft: "10px",
										border: "none",
										borderRadius: "4px",
										left: "535px",
									}}
								>
									Complete
								</Button>
							</div>
						</div>
					)}
				</div>
			</Container>
		</div>
	);
};

export default UpdateSection;
