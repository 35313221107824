/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../../Header";
import AddIcon from "@mui/icons-material/Add";
import { Card } from "primereact/card";
import Form from "react-bootstrap/Form";
import { message } from "antd";
import { MdCheck } from "react-icons/md";
import {
	AddQuestion,
	AddOptions,
	GetOptonsByQuestionId,
	AddCorrecrtAnswer,
} from "../../../Services/API/Course";
import { FaCheckCircle } from "react-icons/fa";
import "./quiz.css";

const QuizPage = () => {
	const navigate = useNavigate();
	const [question, setQuestion] = useState("");
	const [options, setOptions] = useState([""]); // Default empty option
	const [disable, setDisable] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const [questionId, setQuestionId] = useState(null);
	const [optionsList, setOptionsList] = useState([]);
	const [correctAnswer, setCorrectAnswer] = useState("");

	useLayoutEffect(() => {
		if (questionId) {
			GetOptonsByQuestionId(questionId)
				.then((res) => {
					setOptionsList(res.data.data);
					if (res.data.data.length >= 2) {
						setShowOptions(true);
					}
				})
				.catch((err) => {
					console.error(err, "error");
				});
		}
	}, [questionId]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);
		if (!question) {
			message.error("Please write your question first");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("question", question);
			formData.append("course_id", 5);
			formData.append("section_id", 10);
			formData.append("lecture_id", 8);

			const response = await AddQuestion(formData);

			if (response.status === 201) {
				const questionId = response.data.data.id; // Extract the ID from the response
				setQuestionId(questionId); // Save the ID in state
				message.success("Question added successfully!");
				setShowOptions(true);
			}
			setDisable(false);
		} catch (error) {
			handleError(error);
			setDisable(false);
		}
	};

	const handleAddOptionClick = async (index) => {
		if (!options[index]) {
			message.error("Option cannot be empty");
			return;
		}
		try {
			const formData = new FormData();
			formData.append("question_id", questionId);
			formData.append("options", options[index]);

			const response = await AddOptions(formData);
			if (response.status === 201) {
				message.success("Option added successfully!");
				setOptionsList((prevOptions) => [...prevOptions, response.data.data]);
				setOptions((prevOptions) => [...prevOptions, ""]); // Add a new empty option input
			}
		} catch (error) {
			message.error("Failed to add option");
		}
	};

	const handleOptionChange = (index, value) => {
		const newOptions = [...options];
		newOptions[index] = value;
		setOptions(newOptions);
	};

	const handleCorrectAnswer = async (event) => {
		event.preventDefault();
		setDisable(true);
		if (!correctAnswer) {
			message.error("Please provide correct answer");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("question_id", questionId);
			formData.append("right_answer_id", correctAnswer);

			const response = await AddCorrecrtAnswer(formData);

			if (response.status === 201) {
				message.success("Correct answer added successfully!");
				setQuestion("");
				setOptions([""]);
				setDisable(false);
				setCorrectAnswer("");
				document.getElementById("question-input").focus();
			}
		} catch (error) {
			handleError(error);
			setDisable(false);
		}
	};

	const handleError = (error) => {
		if (error.response?.status === 400) {
			message.error("This question already exists");
		} else if (error.response?.status === 401) {
			localStorage.removeItem("adminToken");
			setTimeout(() => {
				navigate("/Login");
			}, 1000);
		} else {
			message.error("Something went wrong");
		}
	};

	const handleViewAllQuestion = (event) => {
		navigate(`/questions/${questionId}`);
	};

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header
					title="Create Quiz Page"
					subtitle="Create Questions, options and provide correct answer"
				/>
				<Button
					className="add_user_button"
					onClick={handleViewAllQuestion}
					sx={{
						background: "#a4a9fc",
						fontSize: "14px",
						fontWeight: "bold",
						padding: "10px 20px",
						marginRight: "0.6rem",
						marginLeft: "10px",
					}}
				>
					View All Questions
				</Button>
			</Box>
			<Card>
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3 w-100">
						<Form.Label>Question:</Form.Label>
						<Form.Control
							type="text"
							required
							placeholder="Please enter Question here"
							value={question}
							onChange={(e) => setQuestion(e.target.value)}
							className="new_form_control"
							id="question-input"
						/>
					</Form.Group>

					<FaCheckCircle
						className="view_all_questions_button"
						onClick={handleSubmit}
						style={{
							marginTop: "-90px",
							marginLeft: "1089px",
							fontSize: "20px",
						}}
					/>

					{showOptions && (
						<>
							{options.map((option, index) => (
								<Form.Group key={index} className="mb-3 w-50">
									<Form.Label>Option {index + 1}:</Form.Label>
									<Form.Control
										type="text"
										placeholder={`Enter option ${index + 1}`}
										value={option}
										onChange={(e) => handleOptionChange(index, e.target.value)}
										className="new_form_control"
									/>
									<Button
										onClick={() => {
											handleAddOptionClick(index);
										}}
										disabled={disable}
										sx={{
											fontSize: "12px",
											fontWeight: "bold",
											padding: "10px",
											marginTop: "20px",
											marginLeft: "5px",
										}}
										variant="contained"
										color="success"
									>
										Add Option
									</Button>
								</Form.Group>
							))}
						</>
					)}

					{optionsList.length >= 2 && (
						<>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Form.Group className="mb-3 w-50 mt-4">
									<Form.Label>Provide Correct Answer:</Form.Label>
									<Form.Select
										aria-label="Default select example"
										placeholder="Select correct answer"
										onChange={(e) => setCorrectAnswer(e.target.value)}
									>
										<option value="">--Select--</option>
										{optionsList.map((option, index) => (
											<option key={index} value={parseInt(option.id)}>
												{option.options}
											</option>
										))}
									</Form.Select>
								</Form.Group>

								<Button
									className="add_question_button"
									onClick={handleCorrectAnswer}
									variant="contained"
									color="success"
									sx={{
										fontSize: "14px",
										fontWeight: "bold",
									}}
								>
									<MdCheck sx={{ mr: "10px" }} />
									Save & Add Questions
								</Button>
							</div>
						</>
					)}
				</Form>
			</Card>
		</Box>
	);
};

export default QuizPage;
