import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { Button as MUIButton } from "@mui/material";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import { BASE_URL, BASE_URL_IMAGE } from "../../Services/host";
import {
  CreateCourseIncludeAPI,
  CreateCourseSectionAPI,
} from "../../Services/API/API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Header from "../../Header";

const CourseDetails = () => {
  let { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedItemId, setEditedItemId] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [sectionHeading, setSectionHeading] = useState("");
  const [editedSectionHeading, setEditedSectionHeading] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [courseOutline, setCourseOutline] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [latestSectionId, setLatestSectionId] = useState(null);
  const [lectureCount, setLectureCount] = useState("");
  const [totalDuration, setTotalDuration] = useState("");

  const navigate = useNavigate();

  const fetchCourseData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/course/getCourseById?course_id=${id}`,
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      );
      setCourseData(response.data.data);
    } catch (error) {
      console.error("Error fetching course data:", error);
    }
  };

  useEffect(() => {
    // Make an API call to fetch the latest section ID
    axios
      .get(`${BASE_URL}/course/getLatestSectionID/${id}`)
      .then((response) => {
        console.log(response.data.data.latestSectionId);
        const latestId = response.data.data.latestSectionId; // Adjust this based on your API response
        setLatestSectionId(latestId);
        console.log(id);
        console.log(latestId);
      })
      .catch((error) => {
        console.error("Error fetching latest section ID:", error);
      });
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  useEffect(() => {
    fetchCourseData();
  }, [id]);

  const handleSectionHeadingChange = (event) => {
    setEditedSectionHeading(event.target.value);
  };

  const navigateToEditSectionDetails = (id) => {
    navigate(`/editSectionDetails/${id}`);
  };

  const handleEditClick = (itemId, sectionHeading) => {
    setEditMode(true);
    setEditedItemId(itemId);
    setEditedSectionHeading(sectionHeading);
  };

  // Function to toggle the form's visibility
  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  const handleConfirmEdit = async (itemId) => {
    try {
      // Update image using PUT request
      console.log(itemId);

      // Create a FormData object to include both image and course outline
      const formData = new FormData();

      if (editedSectionHeading) {
        formData.append("section_heading", editedSectionHeading);
      }

      // Send a PUT request with the FormData
      await axios.put(
        `${BASE_URL}/admin/updateCourseSection/${itemId}`,
        formData,
        {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        }
      );

      // Refresh the data after successful update
      fetchCourseData();
      setEditMode(false);
      setEditedItemId(null);
      setSelectedImageFile(null);
      setEditedSectionHeading("");
    } catch (error) {
      console.error("Error updating course data:", error);
    }
  };

  // Function to handle the delete action
  const handleDelete = async (itemId, sectionHeading) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this Course Section: ${sectionHeading}?`
    );

    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}/admin/deleteCourseSection/${itemId}`, {
          headers: { "x-access-token": localStorage.getItem("adminToken") },
        });

        // Refresh the data after successful delete
        fetchCourseData();
      } catch (error) {
        console.error("Error deleting course section:", error);
      }
    }
  };

  // Code For New Course Include Creation
  // Handle course outline change
  const handleNewCourseOutlineChange = (event) => {
    setCourseOutline(event.target.value);
  };

  // Handle file selection for images
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    if (latestSectionId === null) {
      console.error("Latest section ID is not yet fetched.");
      return;
    }

    console.log(latestSectionId);

    try {
      const formData = {
        course_id: id,
        section_id: latestSectionId + 1,
        section_heading: sectionHeading,
        lecture_count: lectureCount,
        total_duration: totalDuration,
      };

      // Perform your API call with formData.
      // Your API should handle the form data accordingly.
      CreateCourseSectionAPI(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Course Section created successfully!");
          }
        })
        .catch((err) => {});
    } catch (error) {
      // Handle any errors that occurred during the API request (e.g., show error message)
      console.error("Error creating the course section:", error);
    }

    // Clear the form fields after submission if needed.
    setLatestSectionId(latestSectionId + 1);
    setSectionHeading("");
    setLectureCount(0);
    setTotalDuration(0);
  };

  if (!courseData) {
    return <div>Loading...</div>;
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="EDIT SECTION HEADINGS"
          subtitle="Edit Section Headings"
        />

        <div style={{ display: "flex", gap: "28px" }}>
          <Button
            variant="warning"
            className="edit_button"
            onClick={() => {
              navigateToEditSectionDetails(id)
            }}
          >
            Edit Section Details (Lectures)
          </Button>
        </div>
      </Box>

      <div>
        <Typography variant="h4">Course : {courseData.course_name}</Typography>
        <Card>
          <CardContent>
            <Typography variant="subtitle1">Course Sections:</Typography>
            <List>
              {courseData.course_section.map((include) => (
                <div key={include.id}>
                  <ListItem style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flexGrow: 1 }}>
                      <Typography variant="h4" color="textSecondary">
                        Section: {include.section_heading}
                      </Typography>
                    </div>
                    {editMode && editedItemId === include.id ? (
                      <div>
                        <input
                          type="text"
                          value={editedSectionHeading}
                          onChange={handleSectionHeadingChange}
                          style={{ marginRight: "20px" }}
                        />
                        <Button
                          onClick={() => handleConfirmEdit(include.id)}
                          style={{ marginRight: "20px" }}
                        >
                          Confirm
                        </Button>
                        <Button onClick={() => setEditMode(false)}>
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button
                          onClick={() =>
                            handleEditClick(include.id, include.section_heading)
                          }
                          style={{ marginRight: "17px" }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() =>
                            handleDelete(include.id, include.section_heading)
                          }
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </CardContent>
        </Card>
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            marginTop: "23px",
            marginBottom: "50px",
          }}
        >
          <Button onClick={toggleFormVisibility}>
            {showForm ? "Hide Form" : "Add A New Course Section"}
          </Button>
        </div>
        {showForm && (
          <form
            onSubmit={handleSubmit}
            style={{
              maxWidth: "450px",
              width: "100%",
              padding: "20px",
              marginTop: "-60px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              margin: "auto",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Section Heading"
                  value={sectionHeading}
                  onChange={(e) => setSectionHeading(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Lecture Count"
                  value={lectureCount}
                  onChange={(e) => setLectureCount(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Total Duration"
                  value={totalDuration}
                  onChange={(e) => setTotalDuration(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <MUIButton type="submit" variant="contained" color="primary">
                  Create Section
                </MUIButton>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Box>
  );
};

export default CourseDetails;
