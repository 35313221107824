/** @format */

import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Space } from "antd";
import Tooltip from "@mui/material/Tooltip";
import { MdDelete } from "react-icons/md";
const { confirm } = Modal;

const Alert = ({ handleDelete, title }) => {
  const showDeleteConfirm = () => {
    confirm({
      title: `Are you sure you want to delete this ${title}?`,
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        handleDelete(); // Call the handleDelete function provided via props
        // console.log('OK');
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  return (
  <>
    <Tooltip title="Delete">
      <MdDelete
        style={{fontSize: "18px" }}
        onClick={showDeleteConfirm}
      />
    </Tooltip></>
  );
};
export default Alert;
