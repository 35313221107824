/** @format */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../Header";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { DeleteAdmin, GetAdmins } from "../../../Services/API/API";

import Alert from "../../../Constants/Alert";
import { message } from "antd";

function AdminUser() {
	const [pageSize, setPageSize] = useState(50);
	const [dataGridHeight, setDataGridHeight] = useState("550px");
	const [adminData, setAdminData] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [userId, setUserId] = useState();
	const [userIdToNavigate, setUserIdToNavigate] = useState();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	//get all admin user api implementation
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAdmins(localStorage.getItem("adminToken"));
			setAdminData(result.data.data);
			console.log("admins", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");

				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const navigateToEditAdmin = (event, id) => {
		navigate(`/editAdminUser/${id}`);
	};

	const navigateToCreateAdminUser = () => {
		navigate("/createAdminUser");
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = async (data) => {
		setAnchorEl(null);
	};

	const removeUser = (id) => {
		setIsModalVisible(false);
		DeleteAdmin(id, localStorage.getItem("adminToken"))
			.then((res) => {
				console.log(res, "res");
				message.success(res?.data?.message);
				getData();
			})

			.catch((error) => {
				console.log(error, "error");
			});
	};

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: 90,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "name",
			headerName: "Name",
			width: 150,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "email",
			headerName: "Email",
			width: 350,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "role",
			headerName: "Role",
			width: 350,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			flex: 1,
			valueGetter: (params) =>
				params.row.admin_roles.length > 0
					? params.row.admin_roles[0].name
					: "No Role",
		},

		{
			field: "Action",
			headerName: "Actions",
			width: 150,
			sortable: false,
			flex: 1,
			renderCell: (cellValues) => {
				return (
					<div>
						<Tooltip title="Edit Admin">
							<EditIcon
								className="speciality_edit"
								sx={{ marginRight: "15px" }}
								onClick={(event) => {
									navigateToEditAdmin(event, cellValues.id);
								}}
							/>
						</Tooltip>

						<Alert
							title="Admin"
							handleDelete={() => removeUser(cellValues.id)}
						/>
					</div>
				);
			},
		},
	];

	return (
		<Box m="20px">
			{loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
				<>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Header title="ADMIN MANAGEMENT" subtitle=" Admin Users" />
						<Box>
							<Button
								onClick={() => navigateToCreateAdminUser()}
								sx={{
									fontSize: "14px",
									fontWeight: "bold",
									padding: "10px 20px",
									background: "#a4a9fc",
								}}
							>
								<AddIcon sx={{ mr: "10px" }} />
								ADD ADMIN USER
							</Button>
						</Box>
					</Box>
					<div
						style={{
							height: dataGridHeight,
							width: "100%",
							marginTop: "20px",
						}}
					>
						<DataGrid
							rows={adminData}
							columns={columns}
							pageSize={pageSize}
							rowHeight={60}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							rowsPerPageOptions={[50, 100, 150]}
							experimentalFeatures={{ newEditingApi: true }}
							onSelectionModelChange={(id) => {
								const selectedIDs = new Set([id]);
								const selectedRowData = adminData.filter((row) =>
									selectedIDs.has(row.id.toString())
								);
								setUserId(selectedIDs);
							}}
						/>
					</div>
				</>
			)}
		</Box>
	);
}

export default AdminUser;
