/** @format */

import React, { useState, useEffect } from "react";
import { Container, ListGroup, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Services/host";
import Header from "../../Header";
import { DeleteQuestion, GetAllQuestions } from "../../Services/API/API";
import { message } from "antd";

const QuestionList = () => {
	const [questions, setQuestions] = useState([]);
	const [userNames, setUserNames] = useState({});
	const [loading, setLoading] = useState(true);
	const [answerInputs, setAnswerInputs] = useState({}); // State to hold answer inputs
	const [openAnswerId, setOpenAnswerId] = useState(null);
	const [editedAnswers, setEditedAnswers] = useState({});
	const [isAnswerSectionOpen, setIsAnswerSectionOpen] = useState({});

	const navigate = useNavigate();

	const navigateToViewQuestion = (id) => {
		navigate(`/viewQuestion/${id}`);
	};

	const fetchQuestions = async () => {
		try {
			setLoading(true);
			let result = await GetAllQuestions(localStorage.getItem("adminToken"));
			setQuestions(result.data.data);
			console.log("admins", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");

				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchQuestions();
	}, []);

	const handleDelete = async (id) => {
		const confirmed = window.confirm(
			"Do you really want to delete this Question?"
		);
		if (!confirmed) return;

		try {
			const response = await DeleteQuestion(id);
			if (response.status === 200) {
				message.success("Question deleted successfully!");
				// Optionally, you can wait for some time before redirecting or refreshing
				setTimeout(() => {
					window.location.reload(); // Reload the page
				}, 3000);
			}
		} catch (error) {
			if (error.response.status === 401) {
				message.error("Token expired");
			}
			localStorage.removeItem("adminToken");
			setTimeout(() => {
				navigate("/Login");
			}, 3000);
		}
	};

	return (
		<Container className="my-5">
			{loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
				<>
					<Header
						title="QUESTION FORUM"
						subtitle="View,edit and give answers."
					/>
					{loading ? (
						<div className="text-center">
							<div className="my-4">
								<div className="spinner-border" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							</div>
						</div>
					) : (
						<ListGroup>
							{questions.map((question) => (
								<React.Fragment key={question.id}>
									<ListGroup>
										<ListGroupItem className="mb-2 m-2">
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
												}}
											>
												<div>
													<big>
														<p
															className="mb-1"
															style={{ fontWeight: "bold", fontSize: "20px" }}
														>
															{question.question}
														</p>
													</big>
													<big>
														Posted by:{" "}
														{question.user_forum[0].name}
													</big>
												</div>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														gap: "18px",
													}}
												>
													{/* <button className="btn btn-primary ml-2" onClick={() => navigateToViewQuestion(question.id)}>
                        View Question
                      </button> */}
													<button
														className="btn btn-danger ml-2"
														onClick={() => handleDelete(question.id)} // Call handleDelete with question id
													>
														Delete Question
													</button>
												</div>
											</div>
											<div style={{ marginTop: "8px" }}>
												{question.answer ? (
													<div>
														<p style={{ fontSize: "16px" }}>
															Answer: {question.answer}
														</p>
														{openAnswerId === question.id ? (
															<div
																style={{
																	display: "flex",
																	justifyContent: "flex-start",
																	alignItems: "center",
																	gap: "28px",
																}}
															>
																<textarea
																	rows="4"
																	cols="60"
																	style={{
																		maxWidth: "500px",
																		fontSize: "18px",
																	}}
																	value={
																		editedAnswers[question.id] ||
																		question.answer
																	}
																	onChange={(e) =>
																		setEditedAnswers({
																			...editedAnswers,
																			[question.id]: e.target.value,
																		})
																	}
																/>
																<button
																	className="btn btn-success"
																	style={{ maxHeight: "40px" }}
																	onClick={async () => {
																		try {
																			const response = await fetch(
																				`${BASE_URL}/admin/updateAnswer`,
																				{
																					method: "POST",
																					headers: {
																						"Content-Type": "application/json",
																						"x-access-token": `${localStorage.getItem(
																							"adminToken"
																						)}`,
																					},
																					body: JSON.stringify({
																						question_id: question.id,
																						answer: editedAnswers[question.id],
																					}),
																				}
																			);

																			if (response.ok) {
																				message.success(
																					"Answer edited successfully!"
																				);
																				setOpenAnswerId(null);
																				setEditedAnswers({
																					...editedAnswers,
																					[question.id]: "",
																				});
																			} else {
																				console.error(
																					"Failed to update answer"
																				);
																			}
																		} catch (error) {
																			console.error(
																				"Error updating answer:",
																				error
																			);
																		}
																		window.location.reload(true);
																	}}
																>
																	Update Answer
																</button>
																<button
																	className="btn btn-secondary"
																	onClick={() => setOpenAnswerId(null)}
																>
																	Cancel
																</button>
															</div>
														) : (
															<button
																className="btn btn-primary ml-2"
																onClick={() => setOpenAnswerId(question.id)}
															>
																Edit Answer
															</button>
														)}
													</div>
												) : (
													// Display answer input section if answer is not provided
													<div>
														{isAnswerSectionOpen[question.id] ? (
															<div
																style={{
																	display: "flex",
																	justifyContent: "flex-start",
																	alignItems: "center",
																	gap: "28px",
																}}
															>
																<textarea
																	rows="4"
																	cols="40"
																	style={{
																		maxWidth: "500px",
																		fontSize: "18px",
																	}}
																	value={answerInputs[question.id] || ""}
																	onChange={(e) =>
																		setAnswerInputs({
																			...answerInputs,
																			[question.id]: e.target.value,
																		})
																	}
																/>
																<button
																	className="btn btn-success"
																	style={{ maxHeight: "40px" }}
																	onClick={async () => {
																		try {
																			const response = await fetch(
																				`${BASE_URL}/admin/postAnswer`,
																				{
																					method: "POST",
																					headers: {
																						"Content-Type": "application/json",
																						"x-access-token": `${localStorage.getItem(
																							"adminToken"
																						)}`,
																					},
																					body: JSON.stringify({
																						question_id: question.id,
																						answer: answerInputs[question.id],
																					}),
																				}
																			);

																			if (response.ok) {
																				message.success(
																					"Answer posted successfully!"
																				);
																				setOpenAnswerId(null);
																				setAnswerInputs({
																					...answerInputs,
																					[question.id]: "",
																				});
																			} else {
																				console.error(
																					"Failed to submit answer"
																				);
																			}
																		} catch (error) {
																			console.error(
																				"Error submitting answer:",
																				error
																			);
																		}
																		window.location.reload(true);
																	}}
																>
																	Submit Answer
																</button>
																<button
																	className="btn btn-secondary"
																	onClick={() =>
																		setIsAnswerSectionOpen({
																			...isAnswerSectionOpen,
																			[question.id]: false,
																		})
																	}
																>
																	Cancel
																</button>
															</div>
														) : (
															<button
																onClick={() => {
																	setIsAnswerSectionOpen({
																		...isAnswerSectionOpen,
																		[question.id]: true,
																	});
																}}
																className="btn btn-primary mr-2 mb-2"
															>
																Answer
															</button>
														)}
													</div>
												)}
											</div>
										</ListGroupItem>
									</ListGroup>
								</React.Fragment>
							))}
						</ListGroup>
					)}
				</>
			)}
		</Container>
	);
};

export default QuestionList;
