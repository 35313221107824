import { Box } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "../../../Header";

import { GetUserById, UpdateUser } from "../../../Services/API/API";
import { useParams, useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";

const EditUser = () => {
  let { id } = useParams();
  const [idData, setIdData] = React.useState({});

  useLayoutEffect(() => {
    GetUserById(id)
      .then((res) => {
        setIdData(res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  //edit mentee
  const onChange = (e) => {
    setIdData({ ...idData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", idData?.name ? idData?.name : "");
    formData.append("email_id", idData?.email_id ? idData?.email_id : "");
    // formData.append("mobile_number", idData?.mobile_number ? idData?.mobile_number : "");
    console.log(idData.mobile_number);
    console.log("formData", formData);
    UpdateUser(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("User edited successfully!");
        }
      })
      .catch((err) => { });
  };

  const navigate = useNavigate();
  const navigateToUser = () => {
    navigate("/user");
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="EDIT USER" subtitle="Edit User Profile" />
      </Box>
      <div className="User_edit_form">
        <Form className="form" method="post">
          <Row>

            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={idData?.name}
                name="name"
                onChange={(e) => onChange(e)}
                placeholder="Enter name"
                className="form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3" >
              <Form.Label >Email address</Form.Label>
              <Form.Control
                type="email"
                defaultValue={idData?.email_id}
                name="email_id"
                onChange={(e) => onChange(e)}
                placeholder="Enter email"
                className="form_control"
                disabled="true"
              />
            </Form.Group>

            {/* <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="phone"
                  onChange={(e) => onChange(e)}
                  name="mobile_number"
                  defaultValue={idData?.mobile_number}
                  placeholder="Enter phone number"
                  className="form_control"
                />
              </Form.Group> */}

          </Row>

          <div style={{ marginTop: 20 }} >

            <Button
              className="edit_button mx-3"
              variant="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>

            <Button
              variant="dark"
              className="edit_button"
              type="submit"
              onClick={() => navigateToUser()}
            >
              Go Back
            </Button>

          </div>

        </Form>
      </div>
    </Box>
  );
};

export default EditUser;
