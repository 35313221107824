import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios"; // Assuming you're using axios for HTTP requests
import { BASE_URL, BASE_URL_IMAGE } from "../../Services/host";

const ViewQuestion = () => {
  const { id } = useParams();

  const [question, setQuestion] = useState({});
  const [loading, setLoading] = useState(true);
  const [userNames, setUserNames] = useState({});
  const [userProfiles, setUserProfiles] = useState({});

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/admin/getQuestionById?id=${id}`
        );
        setQuestion(response.data.data); // Assuming your API response provides the question data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    const fetchUserProfiles = async () => {
      try {
        const response = await fetch(`${BASE_URL}/admin/getAllUsers`);
        const data = await response.json();
        const profiles = {};
        data.data.forEach((user) => {
          profiles[user.id] = {
            name: user.name,
            profile_picture: user.profile_picture, // Assuming this property exists in the user object
          };
        });
        setUserProfiles(profiles);
      } catch (error) {
        console.error("Error fetching user profiles:", error);
      }
    };

    fetchQuestion();
    fetchUserProfiles();
  }, [id]);

  return (
    <Container className="my-5">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div
          className="card"
          style={{
            maxWidth: "400px",
            maxHeight: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "63vh",
            margin: "auto",
          }}
        >
          <div className="card-body">
            <h2 className="card-title">Question : {question.question}</h2>
            <div className="user-info" style={{display: 'flex', flexDirection: 'row', gap : '20px' ,alignItems: 'center'}}>
              <img
                className="profile-picture"
                src={
                  `${BASE_URL_IMAGE}${
                    userProfiles[question.user_id]?.profile_picture
                  }` || "default_profile_picture_url"
                }
                alt={`${
                  userProfiles[question.user_id]?.name || "Unknown User"
                }'s Profile`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
              <div className="posted-by" style={{fontSize: '20px'}}>
                Posted by:{" "}
                {userProfiles[question.user_id]?.name || "Unknown User"}
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ViewQuestion;
