/** @format */

import React, { useEffect, useState } from "react";
import "./ViewCourse.scss";
import { Accordion, Button, Container, ListGroup } from "react-bootstrap";
import { Rating } from "@mui/material";
// import StudentReview from './StudentReview'
import { GetCourseById } from "../../../Services/API/Course";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL_IMAGE } from "../../../Services/host";
import { Skeleton } from "antd";
const ViewCourse = () => {
	const token = localStorage.getItem("Userlogintoken");
	const { id } = useParams();
	const naviagte = useNavigate();
	const [course, setCourse] = useState();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		GetCourseById(id, token)
			.then((res) => {
				console.log(res, "response");
				setCourse(res?.data?.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error, "error");
			});
	}, [id, token]);
	const handleRedirectLearn = () => {
		naviagte("/coursesection", {
			// state: {
			//   data: course,
			// }
		});
	};
	console.log(course, "course");
	return (
		<Container>
			<div className="CourseDetails">
				<div className="section1">
					<div className="Left">
						{loading ? (
							Array.from({ length: 1 }, (_, index) => (
								<div key={index} style={{ textAlign: "center" }}>
									<Skeleton.Image active={true} className="courseimage" />
								</div>
							))
						) : (
							<img
								src={`${BASE_URL_IMAGE}${course?.course_attachments[0]?.file_name}`}
								alt="courseImage"
								className="courseimage"
							/>
						)}
					</div>
					<div className="Right">
						<h2>{course?.name}</h2>
						<div>
							<Rating
								sx={{ m: 0, p: 0 }}
								size="small"
								readOnly
								precision={1}
								value={parseFloat(course?.rating)} // Convert rating to float
								color="ProgressColor"
							/>
							<small>({parseInt(course?.rating_in_numbers)}) rating</small>
						</div>
						<h6>Author Name : {course?.author_name}</h6>
					</div>
				</div>
				<div className="section2">
					<h5>Description</h5>
					<p dangerouslySetInnerHTML={{ __html: course?.description }}></p>
				</div>
				<div className="section3">
					<Accordion>
						{course?.course_sections?.map((section, sectionIndex) => {
							return (
								<Accordion.Item eventKey={sectionIndex} key={sectionIndex}>
									<Accordion.Header>
										<div>
											<small>Section {sectionIndex + 1}</small>
											<h6>{section?.name}</h6>
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<Accordion>
											{section?.section_lectures.map(
												(lecture, lectureIndex) => (
													<Accordion.Item
														eventKey={lectureIndex}
														key={lectureIndex}
													>
														<Accordion.Header>
															<div>
																<h6>{lecture.name}</h6>
															</div>
														</Accordion.Header>
														<Accordion.Body>
															<div style={{ padding: "10px" }}>
																<p style={{ marginBottom: "5px" }}>
																	Type: {lecture.type}
																</p>
																<p style={{ marginBottom: "5px" }}>
																	Duration: {lecture.duration}
																</p>
																{lecture.type === "Video" ? (
																	<p style={{ marginBottom: "5px" }}>
																		Video Link:{" "}
																		<a href={lecture.video_link}>Watch Video</a>
																	</p>
																) : lecture.type === "Quiz" ? null : (
																	<p style={{ marginBottom: "5px" }}>
																		Content: {lecture.text_content}
																	</p>
																)}
															</div>
														</Accordion.Body>
													</Accordion.Item>
												)
											)}
										</Accordion>
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
					</Accordion>
				</div>
			</div>
		</Container>
	);
};
export default ViewCourse;
