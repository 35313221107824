/** @format */

import { React, useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./Sidebar.css";
import logo from "../../../assets/taxgroup.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import { HiAcademicCap } from "react-icons/hi2";
import { MdForum } from "react-icons/md";
import { RiUserSettingsFill } from "react-icons/ri";
import { MdAddHomeWork } from "react-icons/md";

const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<MenuItem
			active={selected === title}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => setSelected(title)}
			icon={icon}
		>
			<Typography>{title}</Typography>
			{/* <Link to={"#"} /> */}
			<a href={to}></a>
		</MenuItem>
	);
};

const Sidebar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [selected, setSelected] = useState("Dashboard");

	return (
		<Box
			className="sidebar_container"
			sx={{
				"& .pro-sidebar-inner": {
					background: `${colors.primary[400]} !important`,
				},
				"& .pro-icon-wrapper": {
					backgroundColor: "transparent !important",
				},
				"& .pro-inner-item": {
					padding: "5px 35px 5px 20px !important",
				},
				"& .pro-inner-item:hover": {
					color: "#868dfb !important",
				},
				"& .pro-menu-item.active": {
					color: "#6870fa !important",
				},
			}}
		>
			<ProSidebar collapsed={isCollapsed}>
				<Menu iconShape="square">
					{/* LOGO AND MENU ICON */}
					<MenuItem
						onClick={() => setIsCollapsed(!isCollapsed)}
						icon={isCollapsed ? <MoreVertIcon /> : undefined}
						style={{
							// margin: "10px 0 20px 0",
							color: colors.grey[100],
						}}
					></MenuItem>

					{!isCollapsed && (
						<Box
							mb="25px"
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							{/* <Box display="flex" justifyContent="center" alignItems="center"> */}
							<img
								alt="profile-user"
								width="135px"
								height="80px"
								src={logo}
								// style={{ cursor: "pointer", borderRadius: "50%" }}
							/>

							<div className="icon_theme">
								<BsThreeDotsVertical
									className="icon"
									onClick={() => setIsCollapsed(!isCollapsed)}
								/>
							</div>
							{/* </IconButton> */}
						</Box>
					)}

					<Box paddingLeft={isCollapsed ? undefined : "10%"}>
						{/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Home
            </Typography>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<FaHome  style={{ fontSize: '20px' }}/>}
              selected={selected}
              setSelected={setSelected}
            /> */}

						<Typography
							variant="h6"
							color={colors.grey[300]}
							sx={{ m: "15px 0 5px 20px" }}
						>
							Users
						</Typography>
						<Item
							title="Users"
							to="/user"
							icon={<Groups2RoundedIcon style={{ fontSize: "20px" }} />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Typography
							variant="h6"
							color={colors.grey[300]}
							sx={{ m: "15px 0 5px 20px" }}
						>
							Courses
						</Typography>

						<Item
							title="Course List"
							to="/courses"
							icon={<HiAcademicCap style={{ fontSize: "20px" }} />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Certificate"
							to="/courseCertificate"
							icon={<HiAcademicCap style={{ fontSize: "20px" }} />}
							selected={selected}
							setSelected={setSelected}
						/>

						<Typography
							variant="h6"
							color={colors.grey[300]}
							sx={{ m: "15px 0 5px 20px" }}
						>
							Other Content
						</Typography>

						<SubMenu
							title="Landing Page Content"
							icon={<MdAddHomeWork style={{ fontSize: "20px" }} />}
						>
							<Item
								title="About Us"
								to="/aboutUs"
								icon={<MdAddHomeWork style={{ fontSize: "20px" }} />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Calculator Content"
								to="/calculators"
								icon={<MdAddHomeWork style={{ fontSize: "20px" }} />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Let's Get Started"
								to="/letsGetStarted"
								icon={<MdAddHomeWork style={{ fontSize: "20px" }} />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Mission-Vision"
								to="/missionVision"
								icon={<MdAddHomeWork style={{ fontSize: "20px" }} />}
								selected={selected}
								setSelected={setSelected}
							/>
							<Item
								title="Journey Content"
								to="/journey"
								icon={<MdAddHomeWork style={{ fontSize: "20px" }} />}
								selected={selected}
								setSelected={setSelected}
							/>
						</SubMenu>

						<Item
							title="Contact Us"
							to="/contactUsForms"
							icon={<MdForum style={{ fontSize: "20px" }} />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Forum"
							to="/viewForum"
							icon={<MdForum style={{ fontSize: "20px" }} />}
							selected={selected}
							setSelected={setSelected}
						/>

						<Typography
							variant="h6"
							color={colors.grey[300]}
							sx={{ m: "15px 0 5px 20px" }}
						>
							Access - Manager
						</Typography>
						<Item
							title="Admin's List"
							to="/adminUsers"
							icon={<RiUserSettingsFill style={{ fontSize: "20px" }} />}
							selected={selected}
							setSelected={setSelected}
						/>
					</Box>
				</Menu>
			</ProSidebar>
		</Box>
	);
};

export default Sidebar;
