import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetAllQuestions } from "../../../Services/API/Course";

const ViewQuestions = () => {
	const navigate = useNavigate();
	const { questionId } = useParams();
	const [questionList, setQuestionsList] = useState(false);

	useLayoutEffect(() => {
		if (questionId) {
			GetAllQuestions(questionId)
				.then((res) => {
					setQuestionsList(res.data.data);
				})
				.catch((err) => {
					console.error(err, "error");
				});
		}
	}, [questionId]);

	return <div>ViewQuestions</div>;
};

export default ViewQuestions;
