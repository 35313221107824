/** @format */

import "./App.css";
import { React, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Login from "./Components/Pages/Login/Login";
import Sidebar from "./Components/Constants/Sidebar/Sidebar";
import Navbar from "./Components/Constants/Navbar/Navbar";
import ProtectedRoutes from "./Components/Protected/ProtectedRoutes";
import Temp from "./Components/Temp/Temp";
import User from "./Components/Pages/UserManagemet/User/User";
import EditUser from "./Components/Pages/UserManagemet/User/EditUser";
import ViewUser from "./Components/Pages/UserManagemet/User/ViewUser";
import CreateCourseInclude from "./Components/Pages/Courses/CreateCourseInclude";
import CreateCourseSection from "./Components/Pages/Courses/CreateCourseSection";
import CreateSectionDetails from "./Components/Pages/Courses/CreateSectionDetails";
import EditCourse from "./Components/Pages/Courses/EditCourse";
import EditCourseInclude from "./Components/Pages/Courses/EditCourseInclude";
import EditCourseSection from "./Components/Pages/Courses/EditCourseSection";
import EditSectionDetails from "./Components/Pages/Courses/EditSectionDetails";
import ViewForum from "./Components/Pages/Forum/ViewForum";
import ViewQuestion from "./Components/Pages/Forum/ViewQuestion";
import ChangePassword from "./Components/Pages/ChangePassword/ChangePassword";
import AdminProfile from "./Components/Pages/MyAccount/AdminProfile";
import ForgetPassword from "./Components/Pages/ForgetPassword/ForgetPassword";
import EditAdminProfile from "./Components/Pages/MyAccount/EditAdminProfile";
import AdminUser from "./Components/Pages/Admin Management/Admin Add User/AdminUser";
import EditAdminUser from "./Components/Pages/Admin Management/Admin Add User/EditAdminUser";
import CreateAdminUser from "./Components/Pages/Admin Management/Admin Add User/CreateAdminUser";
import AddUser from "./Components/Pages/UserManagemet/User/AddUser";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import ContactUs from "./Components/Pages/ContactUs/ContactUs";
import Course from "./Components/Pages/Course/Course";
import CreateCourse from "./Components/Pages/Course/CreateCourse.jsx";
import CreateSection from "./Components/Pages/Course/CreateSection.jsx";
import CreateLecture from "./Components/Pages/Course/CreateLecture.jsx";
import ViewCourse from "./Components/Pages/Course/View/ViewCourse.jsx";
import UpdateCourse from "./Components/Pages/Course/Edit/UpdateCourse.jsx";
import UpdateSection from "./Components/Pages/Course/Edit/UpdateSection.jsx";
import CourseCertificate from "./Components/Pages/Course/CourseCertificate.jsx";
import Error401 from "./Components/Pages/ErrorPages/Error401.jsx";
import Calculator from "./Components/Pages/Calculator/Calculator.jsx";
import AddCalculator from "./Components/Pages/Calculator/AddCalculator.jsx";
import ViewCalculator from "./Components/Pages/Calculator/ViewCalculator.jsx";
import EditCalculator from "./Components/Pages/Calculator/EditCalculator.jsx";
import Quiz from "./Components/Pages/Course/Quiz/Quiz.jsx";
import ViewQuestions from "./Components/Pages/Course/Quiz/ViewQuestions.jsx";
import AboutUs from "./Components/Pages/Content/AboutUS/AboutUs.jsx";
import EditAboutUs from "./Components/Pages/Content/AboutUS/EditAboutUs.jsx";
import LetsGetStarted from "./Components/Pages/Content/LetsGetStarted/LetsGetStarted.jsx";
import EditLetsGetStared from "./Components/Pages/Content/LetsGetStarted/EditLetsGetStared.jsx";
import MissionVision from "./Components/Pages/Content/MissionVision/MissionVision.jsx";
import EditMissionVision from "./Components/Pages/Content/MissionVision/EditMissionVision.jsx";
import JourneyStarted from "./Components/Pages/Content/JourneyStarted/JourneyStarted.jsx";
import EditJourneyStarted from "./Components/Pages/Content/JourneyStarted/EditJourneyStarted.jsx";

function App() {
	const allPaths = [
		"/",
		"/login",
		"/dashboard",
		"/user",
		"/editUser",
		"/viewUser",
		"deleteUser",
		"/courses",
		"/createCourse",
		"/allCourses",
		"/createCourseInclude",
		"/createCourseSection",
		"/createSectionDetails",
		"/viewCourses",
		"/viewCourse",
		"/editCourse",
		"/editCourseInclude",
		"/editCourseSection",
		"/editCourseSection1",
		"/editSectionDetails",
		"/viewForum",
		"/viewQuestion",
		"/test",
		"/changePassword",
		"/myprofile",
		"/editAdminProfile",
		"/adminUsers",
		"/editAdminUser",
		"/createAdminUser",
		"/addUser",
		"/contactUsForms",
		"/createSection",
		"/createLecture",
		"/editSection",
		"/courseCertificate",
		"/calculators",
		"/addCalculator",
		"/viewCalculator",
		"/editCalculator",
		"/quiz",
		"/questions",
		"/aboutUs",
		"/editAboutUs",
		"/letsGetStarted",
		"/editLetsGetStared",
		"/missionVision",
		"/editMissionVision",
		"/journey",
		"/editJourneyStarted"
	];
	const { pathname } = useLocation();
	const [theme, colorMode] = useMode();
	const [isSidebar, setIsSidebar] = useState(true);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className="app">
					{pathname === "/login" ? null : allPaths.includes(
							"/" + pathname.split("/")[1]
					  ) === true ? (
						<Sidebar isSidebar={isSidebar} />
					) : null}

					<main className="content">
						{pathname === "/login" ? null : allPaths.includes(
								"/" + pathname.split("/")[1]
						  ) === true ? (
							<Navbar setIsSidebar={setIsSidebar} />
						) : null}

						{/*  */}

						<Routes>
							<Route
								exact
								path="/"
								element={
									<ProtectedRoutes Component={Dashboard}>
										<Dashboard />
									</ProtectedRoutes>
								}
							/>
							{/* <Route
                exact
                path="/"
                element={
                  <ProtectedRoutes Component={User}>
                    <User />
                  </ProtectedRoutes>
                }
              /> */}
							<Route exact path="/login" element={<Login />} />
							<Route
								exact
								path="/forgetPassword"
								element={<ForgetPassword />}
							/>
							<Route exact path="/error401" element={<Error401 />} />
							<Route
								exact
								path="/navbar"
								element={
									<ProtectedRoutes Component={Navbar}>
										<Navbar />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/user"
								element={
									<ProtectedRoutes Component={User}>
										<User />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/addUser"
								element={
									<ProtectedRoutes Component={AddUser}>
										<AddUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/adminUsers"
								element={
									<ProtectedRoutes Component={AdminUser}>
										<User />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editAdminUser/:id"
								element={
									<ProtectedRoutes Component={EditAdminUser}>
										<User />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/createAdminUser"
								element={
									<ProtectedRoutes Component={CreateAdminUser}>
										<User />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editUser/:id"
								element={
									<ProtectedRoutes Component={EditUser}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/viewUser/:id"
								element={
									<ProtectedRoutes Component={ViewUser}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/myprofile"
								element={
									<ProtectedRoutes Component={AdminProfile}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editAdminProfile"
								element={
									<ProtectedRoutes Component={EditAdminProfile}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/courses"
								element={
									<ProtectedRoutes Component={Course}>
										<Course />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/quiz"
								element={
									<ProtectedRoutes Component={Quiz}>
										<Quiz />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/questions"
								element={
									<ProtectedRoutes Component={ViewQuestions}>
										<ViewQuestions />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/createCourse"
								element={
									<ProtectedRoutes Component={CreateCourse}>
										<CreateCourse />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/createSection/:id"
								element={
									<ProtectedRoutes Component={CreateSection}>
										<CreateSection />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/createLecture/:id"
								element={
									<ProtectedRoutes Component={CreateLecture}>
										<CreateLecture />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/viewCourse/:id"
								element={
									<ProtectedRoutes Component={ViewCourse}>
										<ViewCourse />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/courseCertificate"
								element={
									<ProtectedRoutes Component={CourseCertificate}>
										<CourseCertificate />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editCourse/:id"
								element={
									<ProtectedRoutes Component={UpdateCourse}>
										<UpdateCourse />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editSection/:id"
								element={
									<ProtectedRoutes Component={UpdateSection}>
										<UpdateSection />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/createCourseInclude"
								element={
									<ProtectedRoutes Component={CreateCourseInclude}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/changePassword"
								element={
									<ProtectedRoutes Component={ChangePassword}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/createCourseSection"
								element={
									<ProtectedRoutes Component={CreateCourseSection}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/createSectionDetails"
								element={
									<ProtectedRoutes Component={CreateSectionDetails}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>

							<Route
								exact
								path="/editCourse/:id"
								element={
									<ProtectedRoutes Component={EditCourse}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editCourseInclude/:id"
								element={
									<ProtectedRoutes Component={EditCourseInclude}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editCourseSection1/:id"
								element={
									<ProtectedRoutes Component={EditCourseSection}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editCourseSection/:id"
								element={
									<ProtectedRoutes Component={EditCourseSection}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editSectionDetails/:id"
								element={
									<ProtectedRoutes Component={EditSectionDetails}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/viewForum"
								element={
									<ProtectedRoutes Component={ViewForum}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/viewQuestion/:id"
								element={
									<ProtectedRoutes Component={ViewQuestion}>
										<EditUser />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/contactUsForms"
								element={
									<ProtectedRoutes Component={ContactUs}>
										<ContactUs />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/calculators"
								element={
									<ProtectedRoutes Component={Calculator}>
										<ContactUs />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/addCalculator"
								element={
									<ProtectedRoutes Component={AddCalculator}>
										<AddCalculator />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/viewCalculator/:id"
								element={
									<ProtectedRoutes Component={ViewCalculator}>
										<ViewCalculator />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editCalculator/:id"
								element={
									<ProtectedRoutes Component={EditCalculator}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/aboutUs"
								element={
									<ProtectedRoutes Component={AboutUs}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/editAboutUs/:id"
								element={
									<ProtectedRoutes Component={EditAboutUs}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>
							<Route
								exact
								path="/letsGetStarted"
								element={
									<ProtectedRoutes Component={LetsGetStarted}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>

							<Route
								exact
								path="/editLetsGetStared/:id"
								element={
									<ProtectedRoutes Component={EditLetsGetStared}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>

							<Route
								exact
								path="/missionVision"
								element={
									<ProtectedRoutes Component={MissionVision}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>

							<Route
								exact
								path="/editMissionVision/:id"
								element={
									<ProtectedRoutes Component={EditMissionVision}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>

<Route
								exact
								path="/journey"
								element={
									<ProtectedRoutes Component={JourneyStarted}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>

							<Route
								exact
								path="/editJourneyStarted/:id"
								element={
									<ProtectedRoutes Component={EditJourneyStarted}>
										<EditCalculator />
									</ProtectedRoutes>
								}
							/>

							<Route
								exact
								path="*"
								element={
									<ProtectedRoutes Component={Temp}>
										<Temp />
									</ProtectedRoutes>
								}
							/>
						</Routes>
					</main>
				</div>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
