/** @format */

import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Header from "../../../Header";
import { Row, Col, Button } from "react-bootstrap";
import { GetUserById } from "../../../Services/API/API";
import { useParams, useNavigate } from "react-router-dom";
import { BASE_URL_IMAGE } from "../../../Services/host";
import { Card } from "primereact/card";
import Form from "react-bootstrap/Form";

const ViewMentor = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  //implementation of getMentorById
  const [idData, setIdData] = React.useState([]);
  const getData = async () => {
    try {
      let result = await GetUserById(id);
      setIdData(result.data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const navigateToEditUser = (event) => {
    navigate(`/editUser/${id}`);
  };

  const navigateToUser = () => {
    navigate("/user");
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="View Profile" subtitle="View User's Information" />
      </Box>
      <Card className="user_profile">
      
          <Form>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>User's Profile Picture:</Form.Label>
                <br />
                {idData?.profile_picture ? (
                  <img
                    src={`${BASE_URL_IMAGE}${idData?.profile_picture}`}
                    alt="User's DP"
                    width="150"
                  />
                ) : (
                  <p>Profile picture Not found</p>
                )}
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    name="name"
                    defaultValue={idData?.name}
                    type="text"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Email Address:</Form.Label>
                <Form.Control
                  name="email"
                  defaultValue={idData?.email_id}
                  type="text"
                  disabled
                />
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile:</Form.Label>
                  <Form.Control
                    name="mobile"
                    defaultValue={idData?.mobile_number}
                    type="text"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <div>
              <Button
                icon="pi pi-arrow-left"
                severity="secondary"
                onClick={(e) => {
                  navigateToUser();
                }}
                style={{ borderRadius: "5px", height: "40px",marginTop:"30px" }}
              >
                <span style={{ marginLeft: "5px" }}> Return to Users</span>
              </Button>
            </div>
          </Form>
      
      </Card>
    </Box>
  );
};

export default ViewMentor;
