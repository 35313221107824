import { Box } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "../../Header";

import { GetCourseByID, UpdateCourse } from "../../Services/API/API";
import { useParams, useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";

const EditUser = () => {
  let { id } = useParams();
  const [idData, setIdData] = React.useState({});

  useLayoutEffect(() => {
    GetCourseByID(id)
      .then((res) => {
        setIdData(res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  //edit mentee
  const onChange = (e) => {
    setIdData({ ...idData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; 
    setIdData({ ...idData, images: file }); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "course_name",
      idData?.course_name ? idData?.course_name : ""
    );
    formData.append(
      "instructor_name",
      idData?.instructor_name ? idData?.instructor_name : ""
    );
    formData.append("images", idData?.images ? idData?.images : "");
    formData.append(
      "difficulty_level",
      idData?.difficulty_level ? idData?.difficulty_level : ""
    );
    formData.append("language", idData?.language ? idData?.language : "");
    formData.append(
      "course_brief_description",
      idData?.course_brief_description ? idData?.course_brief_description : ""
    );
    formData.append(
      "course_description",
      idData?.course_description ? idData?.course_description : ""
    );
    // console.log(idData.mobile_number);
    console.log("formData", formData);
    UpdateCourse(id, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Course edited successfully!");
        }
      })
      .catch((err) => {});
  };

  const navigate = useNavigate();
  const navigateToCourses = () => {
    navigate("/viewCourses");
  };

  const navigateToEditCourseInclude = (id) => {
    navigate(`/editCourseInclude/${id}`);
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="EDIT COURSE" subtitle="Edit Course Profile" />

        <div style={{display: 'flex', gap: "28px" }}>
          <Button
            variant="warning"
            className="edit_button"
            onClick={() => {
              navigateToEditCourseInclude(id)
            }}
          >
            Edit Course Include
          </Button>

          <Button
            variant="warning"
            className="edit_button"
            onClick={() => {
              // Handle the action for editing sections
            }}
          >
            Edit Sections
          </Button>

          <Button
            variant="warning"
            className="edit_button"
            onClick={() => {
              // Handle the action for editing section details (lectures)
            }}
          >
            Edit Section Details (Lectures)
          </Button>
        </div>
      </Box>

      <div className="User_edit_form">
        <Form method="post">
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Course Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={idData?.course_name}
                name="course_name"
                onChange={(e) => onChange(e)}
                placeholder="Enter Course Name"
                className="form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Instructor</Form.Label>
              <Form.Control
                type="text"
                defaultValue={idData?.instructor_name}
                name="instructor_name"
                onChange={(e) => onChange(e)}
                placeholder="Enter Instructor's name"
                className="form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Course Picture</Form.Label>
              <Form.Control
                type="file"
                name="images"
                onChange={(e) => handleImageChange(e)}
                accept="image/*"
                className="form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Difficulty Level</Form.Label>
              <Form.Control
                type="text"
                defaultValue={idData?.difficulty_level}
                name="difficulty_level"
                onChange={(e) => onChange(e)}
                placeholder="Enter Difficulty Level"
                className="form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Language</Form.Label>
              <Form.Control
                type="text"
                defaultValue={idData?.language}
                name="language"
                onChange={(e) => onChange(e)}
                placeholder="Enter Language"
                className="form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>About Course</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => onChange(e)}
                name="course_brief_description"
                defaultValue={idData?.course_brief_description}
                placeholder="Enter brief description about the course."
                className="form_control"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Course Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                onChange={(e) => onChange(e)}
                name="course_description"
                defaultValue={idData?.course_description}
                placeholder="Enter detailed description about the course."
                className="form_control"
              />
            </Form.Group>
          </Row>

          <div
            className="row mt-3"
            style={{ maxWidth: "400px", margin: "auto", gap: "38px" }}
          >
            <Button
              className="edit_button"
              variant="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>

            <Button
              variant="dark"
              className="edit_button"
              type="submit"
              onClick={() => navigateToCourses()}
            >
              Go Back
            </Button>
          </div>
        </Form>
      </div>
    </Box>
  );
};

export default EditUser;
