import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { toast } from "react-toastify";
import { getSections, getLecturesForSection } from "../../Services/API/API";
import { BASE_URL } from "../../Services/host";

function App() {
  const [sections, setSections] = useState([]);
  const [openSectionId, setOpenSectionId] = useState(null);
  const [lectures, setLectures] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [lectureData, setLectureData] = useState({
    youtubeLink: "",
    contentType: "",
    contentDuration: "",
    contentName: "",
  });
  const [courseDuration, setCourseDuration] = useState({
    hours: 0,
    minutes: 0,
  });

  const handleDurationChange = (event) => {
    const { name, value } = event.target;
    setCourseDuration({ ...courseDuration, [name]: value });
  };

  const courseID = sessionStorage.getItem("course_id");

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const sectionsData = await getSections(courseID);
        console.log(sectionsData.data.data.course_section);
        setSections(sectionsData.data.data.course_section);
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };

    fetchSections();
  }, [courseID]);

  const toggleSection = async (courseID, sectionId) => {
    if (sectionId === openSectionId) {
      setOpenSectionId(null);
      setLectures([]);
    } else {
      console.log(courseID);
      setOpenSectionId(sectionId);
      const formData = {
        course_id: courseID,
        section_id: sectionId,
      };
      console.log(formData);
      const lecturesData = await getLecturesForSection(formData);
      setLectures(lecturesData.data.data);
    }
  };

  // Handle lecture data input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLectureData({ ...lectureData, [name]: value });
  };

  // Handle adding a new lecture to the selected section
  const handleAddLecture = async () => {
    // Extract the data from the form inputs and other sources
    const courseDurationString = `${courseDuration.hours} Hours ${courseDuration.minutes} Minutes`;

    const courseId = sessionStorage.getItem("course_id");
    const sectionId = selectedSection.section_id;
    const { youtubeLink, contentType, contentDuration, contentName } =
      lectureData;

    // Create the request body
    const requestBody = {
      course_id: courseId,
      section_id: sectionId,
      content_data: youtubeLink, // Assuming youtubeLink is the content_data field
      content_type: contentType,
      content_duration: courseDurationString,
      content_name: contentName,
    };

    // Make the API request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("adminToken")}`,
      },
      body: JSON.stringify(requestBody),
    };

    await fetch(`${BASE_URL}admin/createSectionDetails`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Lecture added successfully!");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the API response if needed
        console.log("API Response:", data);
        // Reset the lecture data after adding.
        setLectureData({
          youtubeLink: "",
          contentType: "",
          contentDuration: "",
          contentName: "",
        });
        // Optionally, you might want to update the sections list with the newly added lecture
      })
      .catch((error) => console.error("Error adding lecture:", error));
    window.location.reload(true);
  };

  const lectureBoxStyle = {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: "16px",

    width: "40%", // Increase the width of the box
  };

  return (
    <div
      className="App"
      style={{ maxWidth: "1000px", margin: "auto", height: "100vh" }}
    >
      <Typography variant="h2" align="center" gutterBottom>
        Sections and Lectures
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
        }}
      >
        <List style={{ width: "400px" }}>
          {sections.map((section) => (
            <React.Fragment key={section.id}>
              <ListItem
                button
                onClick={() => {
                  toggleSection(courseID, section.section_id);
                  setSelectedSection((prevSection) =>
                    prevSection === section ? null : section
                  );
                }}
              >
                <ListItemText
                  primary={section.section_heading}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "21px", // Adjust the font size as needed
                      fontWeight: "bold",
                      fontFamily: "helvetica",
                    },
                  }}
                />
                <IconButton>
                  {openSectionId === section.section_id ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </ListItem>
              <Collapse
                in={openSectionId === section.section_id}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {lectures.map((lecture) => (
                    <ListItem key={lecture.id} sx={{ pl: 4 }}>
                      <ListItemText
                        primary={`${lecture.content_name}  -  ${lecture.content_duration}`}
                        primaryTypographyProps={{
                          style: {
                            fontSize: "16px", // Adjust the font size as needed
                            fontFamily: "sans-serif",
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>

        {/* If a section is selected, show the "Add Lecture" button */}
        {selectedSection && (
          <Box mt={4} mx="auto" maxWidth="sm" style={lectureBoxStyle}>
            <Typography variant="h4" gutterBottom>
              Add Lecture for {selectedSection.section_heading}
            </Typography>
            {/* Lecture input form */}
            <Box mt={2}>
              <InputLabel>Youtube Link:</InputLabel>
              <TextField
                fullWidth
                name="youtubeLink"
                value={lectureData.youtubeLink}
                onChange={handleChange}
              />
            </Box>
            <Box mt={2}>
              <InputLabel>Content Type:</InputLabel>
              <TextField
                fullWidth
                name="contentType"
                value={lectureData.contentType}
                onChange={handleChange}
              />
            </Box>
            <Box mt={2}>
              <InputLabel style={{marginBottom: '8px'}}>Content Duration:</InputLabel>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  gap: "10px",
                }}
              >
                <TextField
                  type="number"
                  label="Hours"
                  name="hours"
                  value={courseDuration.hours}
                  onChange={handleDurationChange}
                  inputProps={{ min: 0, max: 1000 }}
                />{" "}
                hrs{" "}
                <TextField
                  type="number"
                  label="Minutes"
                  name="minutes"
                  value={courseDuration.minutes}
                  onChange={handleDurationChange}
                  inputProps={{ min: 0, max: 59 }}
                />{" "}
                mins
              </div>
            </Box>
            <Box mt={2}>
              <InputLabel>Content Name:</InputLabel>
              <TextField
                fullWidth
                name="contentName"
                value={lectureData.contentName}
                onChange={handleChange}
              />
            </Box>
            {/* <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsFormExpanded((prevExpanded) => !prevExpanded)}
            >
              {isFormExpanded ? "Hide Form" : "Add Lecture"}
            </Button>
          </Box> */}
            {/* <Collapse in={isFormExpanded}> */}
            {/* Lecture input form */}
            {/* ... (existing JSX code for lecture input fields) */}
            <Box mt={7}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddLecture()}
              >
                Add Lecture
              </Button>
            </Box>
            {/* </Collapse> */}
          </Box>
        )}
      </div>
    </div>
  );
}

export default App;
