/** @format */

import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Card } from "primereact/card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { CreateCalculator } from "../../Services/API/Calculator";
import { useNavigate } from "react-router";
import { message } from "antd";
import "./Calculator.css";
import Header from "../../Header";
import { Box } from "@mui/material";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";

const AddCalculator = () => {
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [redirectionUrl, setRedirectionUrl] = useState("");
	const [images, setImages] = useState(null);
	const [disable, setDisable] = useState(false);
	const [fileList, setFileList] = useState([]);

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setDescription(data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!name) {
			setDisable(false);
			message.error("Please enter calculator Name");
			return;
		}
		if (!description) {
			message.error("Please enter description");
			setDisable(false);
			return;
		}
		if (!redirectionUrl) {
			message.error("Please enter Redirection Url");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("description", description);
			formData.append("redirection_url", redirectionUrl);
			if (images) {
				formData.append("images", images);
			}

			const response = await CreateCalculator(formData);

			if (response.status === 200) {
				message.success("Calculator added successfully");
				setTimeout(() => {
					navigate("/calculators");
				}, 1000);
			}
			setDisable(false);
		} catch (error) {
			if (error.response.status === 400) {
				message.error("Calculator with this title already exist", {
					position: "top-right",
					autoClose: 500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			} else if (error.response.status === 401) {
				message.error("Token expired");
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	const navigateToCalculators = () => {
		navigate("/calculators");
	};

	const onChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};
	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	};

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="CREATE CALCULATOR" subtitle="Create a New Calculator" />
			</Box>
			<Card className="add_calc_form">
				<div>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3">
							<Form.Label> Calculator Name:</Form.Label>
							<Form.Control
								type="text"
								required
								placeholder="Enter Calculator Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								className="new_form_control"
							/>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Image:</Form.Label>
							<ImgCrop rotationSlider>
								<Upload
									action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
									listType="picture-card"
									fileList={fileList}
									onChange={onChange}
									onPreview={onPreview}
								>
									{fileList.length < 1 && "+ Upload"}
									
								</Upload>
							</ImgCrop>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Redirection Url:</Form.Label>
							<Form.Control
								type="text"
								required
								placeholder="Enter Redirection Url"
								value={redirectionUrl}
								onChange={(e) => setRedirectionUrl(e.target.value)}
								className="new_form_control"
							/>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label> Calculator Description:</Form.Label>

							<CKEditor
								editor={ClassicEditor}
								onChange={handleEditorChange}
								config={{
									height: "1000px",
								}}
							/>
						</Form.Group>

						<div style={{ marginTop: "50px" }}>
							<Button
								className="add_new_button1"
								variant="primary"
								type="submit"
								onClick={(e) => {
									handleSubmit(e);
								}}
							>
								Submit
							</Button>

							<Button
								variant="dark"
								className="add_new_button"
								type="submit"
								onClick={() => navigateToCalculators()}
							>
								Go Back
							</Button>
						</div>
					</Form>
				</div>
			</Card>
		</Box>
	);
};

export default AddCalculator;
