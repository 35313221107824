/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Box } from "@mui/material";
// import Header from "../../../Header";
import { useNavigate } from "react-router-dom";
import "./AdminProfile.css";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import { GetAdminProfile } from "../../Services/API/API";
import Header from "../../Header";
import { Card } from "primereact/card";

const AdminProfile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState([]);

  const navigateToAdminProfileEdit = () => {
    navigate("/editAdminProfile");
  };
  const navigateToDashboard = () => {
    navigate("/");
  };

  //api call for get admin profile
  const getData = async () => {
    try {
      let result = await GetAdminProfile();
      console.log("DATA ", result);
      setProfileData(result.data.data);
    } catch (e) {
      console.log("error ", e);
      localStorage.removeItem("adminToken");
      navigate("/login");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ADMIN MANAGEMENT" subtitle="Admin's Profile" />
      </Box>

      <Card className="admin_profile">
        <Form>
          <Row>
            
              <Form.Group className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  name="name"
                  defaultValue={profileData?.name}
                  type="text"
                  disabled
                />
              </Form.Group>
        
           
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                name="name"
                defaultValue={profileData?.email}
                type="text"
                disabled
              />
            </Form.Group>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  defaultValue={profileData?.phone_no}
                  disabled

                />
              </Form.Group>
            </Col>
          </Row>

          <div className="row mt-3 div_button">
            <Button
              className="admin_edit_button"
              variant="dark"
              onClick={(e) => {
                navigateToDashboard();
              }}
            >
              Return To Dashboard
            </Button>
          </div>
        </Form>
      </Card>
    </Box>
  );
};

export default AdminProfile;
