/** @format */

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./ChangePassword.css";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChangeAdminPassword } from "../../Services/API/API";
import { toast } from "react-toastify";
import Header from "../../Header";
import { message } from "antd";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Card } from "primereact/card";

const ChangePassword = () => {
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate("/");
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("old_password", oldPassword);
    formData.append("new_password", newPassword);
    formData.append("confirm_Password", confirmPassword);
    console.log("formdata====>", formData);

    try {
      const res = await ChangeAdminPassword(formData);
      if (res?.status == 200) {
        message.success(" Password updated  Successfully");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        message.error(res?.data?.message, {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="CHANGE PASSWORD"
          subtitle="Change Admin's Password from hereD"
        />
      </Box>
      <Card className="change_pwd_card">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Current Password</Form.Label>
            <div
              className="current-password-toggle-icon"
              onClick={handleTogglePassword}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Current Password"
              onChange={(event) => {
                setOldPassword(event.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <div
              className="new-password-toggle-icon"
              onClick={handleTogglePassword}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder=" New Password"
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Confirm Password</Form.Label>
            <div
              className="confirm-password-toggle-icon"
              onClick={handleTogglePassword}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
            />
          </Form.Group>

          <Button
            variant="primary"
            style={{
              borderRadius: "5px",
              marginLeft: "10px",
              marginTop: "10px",
            }}
            type="submit"
            onClick={(e) => {
              handleChangePassword(e);
            }}
          >
            Submit
          </Button>
          <Button
            style={{
              borderRadius: "5px",
              marginLeft: "10px",
              marginTop: "10px",
            }}
            onClick={(e) => {
              navigateToDashboard();
            }}
            variant="secondary"
          >
            Return to Dashboard
          </Button>
        </Form>
      </Card>
    </Box>
  );
};

export default ChangePassword;
