/** @format */

import React, { useState, useContext, useEffect } from "react";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AdminLogin } from "../../Services/API/API";
import { AuthContext } from "../../Context/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdb-react-ui-kit";
import LoginPageImage from "../../../assets/LoginPageImage.png";
import logo from "../../../assets/taxgroup.png";
import { Button } from "react-bootstrap";
import { message } from "antd";

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const navigateToSendMail = () => {
    navigate("/forgetPassword");
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function onSubmit(e) {
    e.preventDefault();
    try {
      let result = await AdminLogin(formData);

      if (result.status === 200) {
        localStorage.setItem(
          "adminToken",
          result?.data?.data?.token
        );
        message.success(" Logged In !");
        setTimeout(() => {
          navigate("/user");
        }, 1000);
      }
    } catch (error) {
      console.log("ERROR ", error);
      message.error(error?.response?.data?.message);
    }
  }

  return (
    <>
      <MDBContainer className="login_form_container">
        <MDBCard className="login_form_card" style={{ maxHeight: "500px" }}>
          <MDBRow className="g-0">
            <MDBCol md="6">
              <MDBCardImage
                src={LoginPageImage}
                alt="login form"
                style={{
                  width: "79%",
                  height: "100%",
                  maxHeight: "500px",
                  borderRadius: "80px 0px 0px 80px",
                }}
                className="rounded-start"
              />
            </MDBCol>

            <MDBCol md="6" style={{ maxHeight: "500px" }}>
              <MDBCardBody className="d-flex flex-column">
                <div className="d-flex flex-row mt-2">
                  <MDBIcon
                    fas
                    icon="cubes fa-3x me-3"
                    style={{ color: "#ff6219" }}
                  />
                  <span className="h1 fw-bold mb-0">
                    <img src={logo} alt="logo" className="login_logo"></img>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "-28px",
                    marginRight: "17px",
                  }}
                >
                  <h5
                    className="fw-normal my-3 pb-2 login_form_heading"
                    style={{ letterSpacing: "1px" }}
                  >
                    ADMIN LOGIN
                  </h5>

                  {/* <MDBInput
                    wrapperClass="mb-2"
                    label="Email address"
                    id="formControlLg"
                    type="email"
                    required
                    size="lg"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                  /> */}
                  {/* <MDBInput
                    wrapperClass="mb-2"
                    label="Password"
                    id="formControlLg"
                    type="password"
                    size="lg"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                  /> */}

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      name="email"
                      // value={email_id}
                      onChange={(e) => onChange(e)}
                      type="email"
                      placeholder="Enter email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" style={{ position: "relative" }}>
                    <Form.Label>Password</Form.Label>
                    <div
                      className="password-toggle-icon"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                    <Form.Control
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      onChange={(e) => onChange(e)}
                    />
                  </Form.Group>

                  <button
                    className="sigin_form_button mb-4 mt-4"
                    onClick={(e) => {
                      onSubmit(e);
                      // handleClick({ vertical: "top", horizontal: "right" });
                    }}
                  >
                    Login
                  </button>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />

                  {/* <Link to="/SendMail">Forgot password?</Link> */}
                  <Button
                    onClick={navigateToSendMail}
                    className="admiin_forgetpwd_button"
                  >
                    Forget Password?
                  </Button>
                </div>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </MDBContainer>
      {/* <div style ={{display:"flex",justifyContent:"center",marginTop:"-55px"}}>
        <Button
          onClick={navigateToPrivacyPolicy}
          className="privacy_policy_footer"
        >
          Privacy Policy
        </Button>
      </div> */}
    </>
  );
};

export default Login;
