/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Accordion, Col, Container, ListGroup, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaPlus, FaEdit, FaTrashAlt } from "react-icons/fa";
import { message } from "antd";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { GiCheckMark } from "react-icons/gi";
import CloseIcon from "@mui/icons-material/Close";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  AddSection,
  GetCourseById,
  DeleteSection,
  EditSection,
  AddLecture,
  EditLecture,
  DeleteLecture,
} from "../../Services/API/Course";
import LectureForm from "./LectureForm";

const UpdateSection = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLectureDeleteModal, setLectureShowDeleteModal] = useState(false);
  const [sectionAnchorEl, setSectionAnchorEl] = useState(null);
  const [lectureAnchorEl, setLectureAnchorEl] = useState(null);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [showLectureForm, setShowLectureForm] = useState(false);
  const [showLectureEditForm, setShowLectureEditForm] = useState(false);
  const [course, setCourse] = useState({});
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showEditLectureModal, setShowEditLectureModal] = useState(false);

  const handleSectionClick = (event, section) => {
    event.stopPropagation();
    setSectionAnchorEl(event.currentTarget);
    setSelectedSection(section);
  };

  const handleLectureClick = (event, lecture) => {
    event.stopPropagation();
    setLectureAnchorEl(event.currentTarget);
    setSelectedLecture(lecture);
  };

  const handleCloseSectionMenu = () => {
    setSectionAnchorEl(null);
    setSelectedSection(null);
  };

  const handleCloseLectureMenu = () => {
    setLectureAnchorEl(null);
    setSelectedLecture(null);
  };

  const getCourseData = () => {
    GetCourseById(id)
      .then((res) => {
        setCourseData(res.data.data);
        console.log("GetCourseById=>", res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useLayoutEffect(() => {
    getCourseData();
  }, [id]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setName(""); // Reset the name state to clear previous entry
    setShow(true);
  };

  const handleAddSection = async (event) => {
    event.preventDefault();
    setDisable(true);
    if (!name) {
      message.error("Please enter Section Name");
      setDisable(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("course_id", id);
      formData.append("name", name);

      const response = await AddSection(formData);

      if (response.status === 201) {
        message.success("Section added successfully!");
        handleClose(); // Close modal after successful addition
        getCourseData(); // Refresh course data after addition
      }
      setDisable(false);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      } else {
        message.error("Something went wrong");
      }
      setDisable(false);
    }
  };

  const handleEditSectionClick = (section) => {
    handleCloseSectionMenu();
    setSelectedSection(section);
    setShowEditSectionModal(true);
    setShowEditLectureModal(false);
  };

  const handleEditSection = (e) => {
    if (!selectedSection || !selectedSection.id) {
      console.error("No section selected or invalid section ID");
      return;
    }

    const formData = new FormData();
    formData.append("name", selectedSection.name); // Use selectedSection name

    EditSection(selectedSection.id, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Section edited successfully!");
          getCourseData();
          setShowEditSectionModal(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const handleSectionDeleteClick = (section) => {
    handleCloseSectionMenu();
    setSelectedSection(section);
    setShowDeleteModal(true);
  };

  const handleSectionDelete = (section_id) => {
    DeleteSection(section_id, localStorage.getItem("adminToken"))
      .then((res) => {
        console.log(res, "res");
        message.success(res?.data?.message);
        getCourseData();
        setShowDeleteModal(false); // Close delete modal after successful deletion
      })
      .catch((error) => {
        console.log(error, "error");
        setShowDeleteModal(false); // Close delete modal even on error
      });
  };

  //lecture functions here
  const handleAddLecture = (sectionId) => {
    setSelectedSectionId(sectionId);
    setShowLectureForm(true);
  };

  // const handleLectureSubmit = (courseId, sectionId) => {
  // 	// Logic to save lecture
  // 	setShowLectureForm(false); // Close the lecture form after saving
  // };

  const handleLectureSubmit = async (formData) => {
    setDisable(true);
    try {
      const data = new FormData();
      data.append("course_id", formData.courseId);
      data.append("section_id", formData.sectionId);
      data.append("name", formData.lectureName);
      data.append("duration", formData.duration);
      data.append("type", formData.lectureType);
      data.append("video_link", formData.videoLink);
      data.append("text_content", formData.textContent);
      if (formData.document) {
        for (let i = 0; i < formData.document.length; i++) {
          data.append("docs", formData.document[i]);
        }
      }

      const response = await AddLecture(data);
      console.log("AddLecture response:", response); // Log the response

      if (response && response.status === 201) {
        message.success("Lecture added successfully!");
        setShowLectureForm(false);
		getCourseData();

        // Update course data if needed
        const updatedCourse = { ...course };
        const sectionIndex = updatedCourse.course_sections.findIndex(
          (sec) => sec.id === formData.sectionId
        );
        updatedCourse.course_sections[sectionIndex].section_lectures.push(
          response.data.data
        );
        setCourse(updatedCourse);
      } else {
        message.error("Failed to add lecture. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleLectureSubmit:", error); // Log the error
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 1000);
      }
    } finally {
      setDisable(false);
    }
  };

  const handleLectureDeleteClick = (section) => {
    handleCloseLectureMenu();
    setSelectedSection(section);
    setLectureShowDeleteModal(true);
  };

  const handleLectureDelete = (section_id) => {
    DeleteLecture(section_id, localStorage.getItem("adminToken"))
      .then((res) => {
        console.log(res, "res");
        message.success(res?.data?.message);
        getCourseData();
        setLectureShowDeleteModal(false); // Close delete modal after successful deletion
      })
      .catch((error) => {
        console.log(error, "error");
        setLectureShowDeleteModal(false); // Close delete modal even on error
      });
  };

  const handleEditLectureClick = (lecture) => {
    handleCloseLectureMenu();
    setSelectedLecture(lecture);
    setShowEditLectureModal(true);
    setShowEditSectionModal(false); // Ensure the other modal is closed
  };

  const handleEditorChange = (event, editor) => {
	const data = editor.getData();
	// setTextContent(data);
};

  const handleEditLecture = (e) => {
    if (!selectedLecture || !selectedLecture.id) {
      console.error("No lecture selected or invalid lecture ID");
      return;
    }

    const formData = new FormData();
    formData.append("name", selectedLecture.name); // Use selectedLecture name

    EditLecture(selectedLecture.id, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Lecture edited successfully!");
          getCourseData();
          setShowEditLectureModal(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          message.error("Token expired!");
          localStorage.removeItem("adminToken");
          setTimeout(() => {
            navigate("/Login");
          }, 3000);
        } else {
          message.error("Something went wrong");
        }
      });
  };

  const handleCancel = () => {
    setShowLectureForm(false); // Close the lecture form on cancel
  };

  const handleCancelEditLecture = () => {
    setShowLectureEditForm(false);
  };

  const navigateToCourse = () => {
    navigate("/courses");
  };

  console.log(courseData, "courseData");
  return (
    <div className="CourseLearn">
      <Container>
        <div className="main">
          <div className="course_list">
            <h3>{courseData?.name}</h3>
            <div className="List_scroll">
              {courseData?.course_sections?.length > 0 ? (
                <Accordion>
                  {courseData?.course_sections?.map((section, sectionIndex) => (
                    <Accordion.Item eventKey={sectionIndex} key={sectionIndex}>
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div className="width_full">
                            <div className="section_name">
                              <h5>Section {sectionIndex + 1}</h5>
                            </div>
                            <h6>{section?.name}</h6>
                          </div>

                          <div style={{ marginLeft: "auto" }}>
                            <IconButton
                              aria-label="more"
                              aria-controls="section-menu"
                              aria-haspopup="true"
                              onClick={(e) => handleSectionClick(e, section)}
                            >
                              <HiDotsHorizontal
                                style={{
                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                              />
                            </IconButton>
                            {selectedSection &&
                              selectedSection.id === section.id && (
                                <Menu
                                  id="section-menu"
                                  anchorEl={sectionAnchorEl}
                                  keepMounted
                                  open={Boolean(sectionAnchorEl)}
                                  onClose={handleCloseSectionMenu}
                                >
                                  <MenuItem
                                    onClick={() =>
                                      handleEditSectionClick(section)
                                    }
                                  >
                                    <FaEdit style={{ marginRight: "5px" }} />
                                    Edit Section
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleSectionDeleteClick(section)
                                    }
                                  >
                                    <span style={{ color: "red" }}>
                                      <FaTrashAlt
                                        style={{ marginRight: "5px" }}
                                      />
                                      Delete Section
                                    </span>
                                  </MenuItem>
                                </Menu>
                              )}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ListGroup>
                          {section?.section_lectures?.map(
                            (lecture, lectureIndex) => (
                              <ListGroup.Item
                                key={lectureIndex}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="section_name">
                                  <div>{lecture.name}</div>
                                </div>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(e) =>
                                    handleLectureClick(e, lecture)
                                  }
                                >
                                  <HiDotsHorizontal
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                </IconButton>

                                {selectedLecture &&
                                  selectedLecture.id === lecture.id && (
                                    <Menu
                                      id="lecture-menu"
                                      anchorEl={lectureAnchorEl}
                                      keepMounted
                                      open={Boolean(lectureAnchorEl)}
                                      onClose={handleCloseLectureMenu}
                                    >
                                      <MenuItem
                                        onClick={() =>
                                          handleEditLectureClick(lecture)
                                        }
                                      >
                                        <FaEdit
                                          style={{ marginRight: "5px" }}
                                        />
                                        Edit Lecture
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleLectureDeleteClick(lecture)
                                        }
                                      >
                                        <span style={{ color: "red" }}>
                                          <FaTrashAlt
                                            style={{ marginRight: "5px" }}
                                          />
                                          Delete Lecture
                                        </span>
                                      </MenuItem>
                                    </Menu>
                                  )}
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                        <Button
                          startIcon={<FaPlus />}
                          variant="outlined"
                          onClick={() => handleAddLecture(section.id)}
                          sx={{
                            background: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px",
                            margin: "20px",
                            border: "none",
                            width: "91%",
                            borderRadius: "0",
                          }}
                        >
                          Add New Lecture
                        </Button>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              ) : (
                <div className="no-lecture-container">
                  <p>No Section Added</p>
                </div>
              )}
              <Button
                startIcon={<FaPlus />}
                variant="outlined"
                onClick={handleShow}
                sx={{
                  background: "#a4a9fc",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px",
                  marginRight: "0.6rem",
                  marginLeft: "10px",
                  border: "none",
                  width: "97%",
                  borderRadius: "0",
                }}
              >
                Add New Section
              </Button>

              {/* add new section modal */}
              <Modal
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Section Name:</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Please enter your Section Name!"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="new_form_control"
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={handleClose}
                    sx={{
                      width: "100px",
                      color: "white",
                      background: "#7F7F7F",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    className="save_section_button"
                    sx={{
                      background: "#a4a9fc",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                    }}
                    onClick={handleAddSection}
                    disabled={disable}
                  >
                    {disable ? "Saving...." : "Save"}
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Edit Section Modal */}
              <Modal
                show={showEditSectionModal}
                onHide={() => setShowEditSectionModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Edit form */}
                  {selectedSection && (
                    <Form>
                      <Form.Group controlId="sectionName">
                        <Form.Label>Section Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter section name"
                          value={selectedSection.name}
                          // Update selectedSection.name
                          onChange={(e) =>
                            setSelectedSection({
                              ...selectedSection,
                              name: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Form>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => setShowEditSectionModal(false)}
                    sx={{
                      width: "100px",
                      color: "white",
                      background: "#7F7F7F",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                      cursor: "cell",
                    }}
                  >
                    Close
                  </Button>

                  <Button
                    className="save_section_button"
                    sx={{
                      background: "#a4a9fc",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                    }}
                    onClick={handleEditSection}
                    disabled={disable}
                  >
                    {disable ? "Saving...." : "Save"}
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Delete Section Modal */}
              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to delete this Section?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => setShowDeleteModal(false)}
                    sx={{
                      width: "100px",
                      color: "white",
                      background: "#7F7F7F",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                      cursor: "cell",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="delete_section_button"
                    sx={{
                      background: "#f44336",
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleSectionDelete(selectedSection.id)}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Delete Lecture Modal */}
              <Modal
                show={showLectureDeleteModal}
                onHide={() => setLectureShowDeleteModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete Lecture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to delete this Lecture?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => setLectureShowDeleteModal(false)}
                    sx={{
                      width: "100px",
                      color: "white",
                      background: "#7F7F7F",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                      cursor: "cell",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="delete_section_button"
                    sx={{
                      background: "#f44336",
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleLectureDelete(selectedSection.id)}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Edit lECTURE Modal */}
              <Modal
                show={showEditLectureModal}
                onHide={() => setShowEditLectureModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Lecture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Edit form */}
                  {selectedLecture && (
                    <Form>
                      <Form.Group controlId="lectureName">
                        <Form.Label>Lecture Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter lecture name"
                          value={selectedLecture.name}
                          // Update selectedLecture.name
                          onChange={(e) =>
                            setSelectedLecture({
                              ...selectedLecture,
                              name: e.target.value,
                            })
                          }
                        />
                      </Form.Group>

					  {selectedLecture.type === "Video" && (
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Video Link:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Please enter Video Link"
                                        // value={videoLink}
                                        // onChange={(e) => setVideoLink(e.target.value)}
                                        className="new_form_control"
                                    />
                                </Form.Group>
                            </Col>
                        )}
						  {selectedLecture.type === "Text" && (
                            <Col>
                                <Form.Group className="mb-3">
                                <Form.Label>Text Content:</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    onChange={handleEditorChange}
                                    config={{
                                        height: "1000px",
                                    }}
                                />
                            </Form.Group>
                            </Col>
                        )}
						  <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Duration:</Form.Label>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className="d-flex align-items-center"
                                    style={{ width: "50%", marginRight: "10px" }}
                                >
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        max="24"
                                        required
                                        // placeholder="Hours"
                                        // value={hours}
                                        // onChange={(e) => setHours(e.target.value)}
                                        className="new_form_control mr-2"
                                    />
                                    <span>Hours</span>
                                </div>
                                <div
                                    className="d-flex align-items-center mt-2"
                                    style={{ width: "50%" }}
                                >
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        max="59"
                                        required
                                        placeholder="Minutes"
                                        // value={minutes}
                                        // onChange={(e) => setMinutes(e.target.value)}
                                        className="new_form_control mr-2"
                                    />
                                    <span>Minutes</span>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Documents:</Form.Label>
                            <Form.Control
                                type="file"
                                required
                                multiple
                                accept=".docx, .doc, .pdf, .xls, .xlsx, .csv"
                                id="documentInput"
                                // ref={fileInputRef}
                                // onChange={handleDocumentChange}
                            />
                        </Form.Group>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* {document &&
                                documentNames.map((name, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        <p
                                            style={{
                                                position: "relative",
                                                backgroundColor: "#EBECF2",
                                                borderRadius: "8px",
                                                width: "210px",
                                                padding: "8px",
                                                alignItems: "center",
                                                height: "40px",
                                                marginTop: "30px",
                                            }}
                                        >
                                            {name}
                                        </p>
                                        <CloseIcon
                                            fontSize="small"
                                            color="warning"
                                            onClick={() => handleRemoveDocument(index)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                ))} */}
                        </div>
                    </Row>
                    </Form>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => setShowEditLectureModal(false)}
                    sx={{
                      width: "100px",
                      color: "white",
                      background: "#7F7F7F",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                      cursor: "cell",
                    }}
                  >
                    Close
                  </Button>

                  <Button
                    className="save_lecture_button"
                    sx={{
                      background: "#a4a9fc",
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px",
                      marginRight: "0.6rem",
                      marginLeft: "10px",
                    }}
                    onClick={handleEditLecture}
                    disabled={disable}
                  >
                    {disable ? "Saving...." : "Save"}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          {/* add new lecture */}
          {showLectureForm ? (
            <LectureForm
              courseId={id}
              sectionId={selectedSectionId}
              handleLectureSubmit={handleLectureSubmit}
              onCancel={handleCancel}
            />
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="no-lecture-container">
                <p>No Lectures Added</p>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={navigateToCourse}
                  startIcon={<GiCheckMark style={{ marginRight: "0px" }} />}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px",
                    marginLeft: "10px",
                    border: "none",
                    borderRadius: "4px",
                    left: "535px",
                  }}
                >
                  Complete
                </Button>
              </div>
            </div>
          )}

          {/* edit lecture */}

          {/* {showLectureEditForm ? (
						<EditLectureForm
							courseId={id}
							sectionId={selectedSectionId}
							handleLectureSubmit={handleLectureSubmit}
							onCancel={handleCancel}
						/>
					) : (
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div className="no-lecture-container">
								<p>No Lectures Added</p>
							</div>
							<div>
								<Button
									variant="contained"
									color="success"
									onClick={navigateToCourse}
									startIcon={<GiCheckMark style={{ marginRight: "0px" }} />}
									sx={{
										fontSize: "14px",
										fontWeight: "bold",
										padding: "10px",
										marginLeft: "10px",
										border: "none",
										borderRadius: "4px",
										left: "535px",
									}}
								>
									Complete
								</Button>
							</div>
						</div>
					)} */}
        </div>
      </Container>
    </div>
  );
};

export default UpdateSection;
