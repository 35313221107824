
import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { message } from "antd";
import {
	GetAboutUsById,
	UpdateAboutUs,
} from "../../../Services/API/Calculator";
import { Card } from "primereact/card";
import { BASE_URL_IMAGE } from "../../../Services/host";
import Header from "../../../Header";

const EditAboutUs = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [proposalData, setProposalData] = useState([]);
	const [checkImage, setCheckImage] = React.useState(null);
	const [image, setImage] = useState({ preview: "", raw: "" });

	const navigateToCalculators = () => {
		navigate("/aboutUs");
	};

	//get proposal By ID
	useLayoutEffect(() => {
		GetAboutUsById(id)
			.then((res) => {
				setProposalData(res.data.data);
				setCheckImage(res.data.data.file_name);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	const handleImageChange = (e) => {
		if (e.target.files.length) {
			setImage({
				preview: URL.createObjectURL(e.target.files[0]),
				raw: e.target.files[0],
			});
		}
		setProposalData({ ...proposalData, image: e.target.files[0] });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("name", proposalData?.name ? proposalData?.name : "");
	
		if (proposalData.image) {
			formData.append("images", proposalData.image);
		}

		UpdateAboutUs(id,formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Calculator edited successfully!");
				}
				navigate("/aboutUs");
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			});
	};


	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Header title="UPDATE ABOUT US IMAGE" subtitle="Edit Image" />
			</Box>

			<Card className="add_calc_form">
				<div >
					<Form className="admin_details_form">
					
						
						<Row>
							<Form.Group className="mb-3">
								<Form.Label>Image:</Form.Label>

								<Form.Control
									type="file"
									name="image"
									onChange={(e) => handleImageChange(e)}
								/>
								{proposalData ? (
									<>
										{image.preview === "" ? (
											<img
												src={`${BASE_URL_IMAGE}${proposalData.file_name}`}
												alt="Proposal Banner "
												style={{
													height: "100px",
													width: "100px",
													marginTop: "20px",
													marginLeft: "20px",
												}}
											/>
										) : (
											<img
												src={image.preview}
												alt="Preview"
												style={{
													height: "100px",
													width: "100px",
													marginTop: "20px",
													marginLeft: "20px",
												}}
											/>
										)}
									</>
								) : (
									<span>No Image Available</span>
								)}
							</Form.Group>
						</Row>

					
						<div style={{ marginTop: "50px" }}>
							<Button
								className="add_new_button1"
								variant="primary"
								type="submit"
								onClick={(e) => {
									handleSubmit(e);
								}}
							>
								Submit
							</Button>
							<Button
								variant="dark"
								className="add_new_button"
								type="submit"
								onClick={() => navigateToCalculators()}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</div>
			</Card>
		</Box>
	);
};

export default EditAboutUs;
