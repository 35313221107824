/** @format */

import { Box, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../../Header";
import { useNavigate } from "react-router-dom";
import {
	GetMissionVision,
	DeleteMissionVision,
} from "../.././../Services/API/Calculator";
import { message } from "antd";
import Alert from "../../../Constants/Alert";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Tooltip from "@mui/material/Tooltip";

const MissionVision = () => {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(50);
	const [dataGridHeight, setDataGridHeight] = useState("550px");
	const [roleData, setRoleData] = useState([]);
	const [anchorEl, setAnchorEl] = React.useState();
	const [userId, setUserId] = useState();
	const [userIdToNavigate, setUserIdToNavigate] = useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleClick = (event, value) => {
		setUserIdToNavigate(value);
		setAnchorEl(event.currentTarget);
	};

	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetMissionVision(localStorage.getItem("adminToken"));
			setRoleData(result.data.data);
			console.log("admins", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");

				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const removeCalaculator = (user_id) => {
		setIsModalVisible(false);
		DeleteMissionVision(user_id, localStorage.getItem("adminToken"))
			.then((res) => {
				console.log(res, "res");
				message.success(res?.data?.message);
				getData();
			})

			.catch((error) => {
				console.log(error, "error");
			});
	};


	const navigateToEditCalculator = (event, id) => {
		navigate(`/editMissionVision/${id}`);
	};


	const columns = [
		{ field: "id", headerName: "ID", width: 150 },
		{
			field: "name",
			headerName: "Heading",
			width: 350,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			flex: 1,
		},

		{
			field: "action",
			headerName: "Actions",
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			width: "350",
			flex: 1,
			renderCell: (cellValues) => {
				return (
					<div>
						
						<Tooltip title="Edit">
							<ModeEditIcon
								className="speciality_edit"
								sx={{ marginRight: "15px" }}
								onClick={(event) =>
									navigateToEditCalculator(event, cellValues.id)
								}
							/>
						</Tooltip>
						<Alert
							title="About Us Image"
							handleDelete={() => removeCalaculator(cellValues.id)}
						/>
					</div>
				);
			},
		},
	];

	return (
		<Box m="20px">
			{loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
			<>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Header
						title="Mission Vision Image "
						subtitle="View,Edit & Delete"
					/>
				
				</Box>
				<div
					style={{
						height: dataGridHeight,
						width: "100%",
						// marginLeft: "10%",
						marginTop: "20px",
					}}
				>
					<DataGrid
						rows={roleData}
						columns={columns}
						pageSize={pageSize}
						rowHeight={80}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						rowsPerPageOptions={[50, 75, 100]}
						experimentalFeatures={{ newEditingApi: true }}
						onSelectionModelChange={(id) => {
							const selectedIDs = new Set([id]);
							const selectedRowData = roleData.filter((row) =>
								selectedIDs.has(row.id.toString())
							);
							setUserId(selectedIDs);
						}}
					/>
				</div>
			</>
			)}
		</Box>
	);
};

export default MissionVision;
