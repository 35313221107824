import axios from "axios";
import { BASE_URL } from "../host";

//admin login
export const AdminLogin = async (data) => {
  let config = {
    email: data.email,
    password: data.password,
  };
  return await axios.post(BASE_URL + "admin/login", config);
};

//Get All User
export const GetAllUser = async (adminToken) => {
  try {
    const res = await axios.get(
      BASE_URL + "admin/getAllUsers",
      {
        headers: {
          "x-access-token": `${adminToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return res;
  } catch (error) {
    // Handle error if needed
    console.error("Error fetching users:", error);
    throw error;
  }
};

//get user by Id
export const GetUserById = async (id) => {
  return await axios.get(BASE_URL + "admin/getUserById?id=" + id);
};

//Edit User
export const UpdateUser = async (formData) => {
  return await axios.put(BASE_URL + "admin/editUser", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const CreateUser = async (formData) => {
  return await axios.post(BASE_URL + "admin/addUser", formData, {
   
  });
};

//delete user
export const DeleteUser = async (id) => {
  return await axios.delete(BASE_URL + "admin/deleteUser?id=" + id, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": localStorage.getItem("adminToken"),
    },
  });
};

//Get All Courses
export const GetAllCourses = async () => {
  const res = await axios.get(BASE_URL + "course/getCourses", {});
  return res;
};

//Get a course by ID
export const GetCourseByID = async (id) => {
  const res = await axios.get(
    BASE_URL + "course/getCourseById?course_id=" + id ,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": localStorage.getItem("adminToken"),
      },
    }
  );
  return res;
};

//delete user
export const DeleteCourse = async (id) => {
  return await axios.delete(BASE_URL + "/admin/deleteCourse/" + id, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": localStorage.getItem("adminToken"),
    },
  });
};

// Function to create a course
export const CreateCourse = async (formData) => {
  return await axios.post(BASE_URL + "admin/createCourse", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Function to Update A  Course
export const UpdateCourse = async (courseId, formData) => {
  console.log(formData.language)
  return await axios.put(BASE_URL + `admin/updateCourse/${courseId}`, formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Function to create what Course includes
export const CreateCourseIncludeAPI = async (formData) => {
  return await axios.post(BASE_URL + "admin/createCourseInclude", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Function to create Course Sections
export const CreateCourseSectionAPI = async (formData) => {
  return await axios.post(BASE_URL + "admin/createCourseSection", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Function to send otp
export const Send_OTP_API = async (formData) => {
  return await axios.post(BASE_URL + "admin/otp", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Function to send otp
export const ForgetPassword_API = async (formData) => {
  return await axios.post(BASE_URL + "admin/otp", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//edit admin profile
export const EditAdminDetails = async (data) => {
  const token = localStorage.getItem("adminToken");
  return axios.post(BASE_URL + "admin/editAdminProfile", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": `${token}`,
    },
  });
};

// Function to get Course Sections
export const getSections = async (courseID) => {
  return await axios.get(BASE_URL + `course/getCourseById?course_id=${courseID}`, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Function to get Lectures For Section
export const getLecturesForSection = async (formData) => {
  return await axios.post(BASE_URL + `course/getSectionContentBySectionId`, formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//change mentor password
export const ChangeMentorPassord = async (value1) => {
  const formData = new FormData();
  formData.append("email_id", value1);
  return axios.post(BASE_URL + "admin/changeMentorPassword", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": localStorage.getItem("adminToken"),
    },
  });
};

//change admin password
export const ChangeAdminPassword = async (data) => {
  return axios.post(BASE_URL + "admin/changePassword", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": localStorage.getItem("adminToken"),
    },
  });
};

//get admin profile
export const GetAdminProfile = async () => {
  const token = localStorage.getItem("adminToken");
  return await axios.get(BASE_URL + "admin/getadminProfile", {
    headers: {
      "x-access-token": `${token}`,
      // "Content-Type": "multipart/form-data",
    },
  });
};

//change mentee password
export const ChangeMenteePassord = async (value1) => {
  const formData = new FormData();
  formData.append("email_id", value1);
  return axios.post(BASE_URL + "admin/changeMenteePassword", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": localStorage.getItem("adminToken"),
    },
  });
};

//add mentor/mentee
export const AddUser = async ({ user_type, email_id, name }) => {
  const formData = new FormData();
  formData.append("user_type", user_type);
  formData.append("email_id", email_id);
  formData.append("name", name);

  return await axios.post(BASE_URL + "admin/createUser", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//Chnage mentor/mentee status
export const ChangeUserStatus = async ({
  user_type,
  email_id,
  message,
  status,
}) => {
  const formData = new FormData();
  formData.append("user_type", user_type);
  formData.append("email_id", email_id);
  formData.append("status", status);
  formData.append("message", message);

  return await axios.post(BASE_URL + "admin/changeUserStatus", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

//Get Admin user
export const GetAdmins = async (adminToken, role_id) => {
  try {
    const res = await axios.get(BASE_URL + "admin/getAllAdmin", {
      headers: {
        "x-access-token": `${adminToken}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return res;
  } catch (error) {
    // Handle error if needed
    console.error("Error fetching users:", error);
    throw error;
  }
};



export const DeleteAdmin = async (id) => {
  return await axios.delete(BASE_URL + "admin/deleteAdmin?id=" + id, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": localStorage.getItem("adminToken"),
    },
  });
};


//add admin
export const CreateAdmin = async (formData) => {
  return await axios.post(BASE_URL + "admin/register", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const GetAdminById = async (id) => {
  return await axios.get(BASE_URL + "admin/getAdminById?id=" + id);
};

export const UpdateAdmin = async (formData) => {
  return await axios.put(BASE_URL + "admin/updateAdmin", formData, {
    headers: {
      "x-access-token": `${localStorage.getItem("adminToken")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};


export const GetAllContactUs = async (adminToken, role_id) => {
  try {
    const res = await axios.get(BASE_URL + "user/getAllContactForm", {
      headers: {
        "x-access-token": `${adminToken}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return res;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const GetAllQuestions = async (adminToken, role_id) => {
  try {
    const res = await axios.get(BASE_URL + "admin/getAllQuestions", {
      headers: {
        "x-access-token": `${adminToken}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return res;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


export const DeleteQuestion = async (id) => {
  return await axios.delete(BASE_URL + "admin/deleteQuestion?id=" + id, {
   
  });
};
