import axios from "axios";
import { BASE_URL } from "../host";


//calculator api
export const CreateCalculator = async (formData) => {
	return await axios.post(
		BASE_URL + "calculator/createCalculatorDescription",
		formData,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const GetAllCalculator = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "calculator/getAllCalculatorDescription", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetCalculatorById = async (id) => {
	return await axios.get(
		BASE_URL + `calculator/getCalculatorDescriptionById/${id}`
	);
};

export const UpdateCalculator = async (id,formData) => {
	return await axios.put(
		BASE_URL + `calculator/updateCalculatorDescription/${id}`,formData,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const DeleteCalculator = async (id) => {
	return axios.delete(
		BASE_URL + `calculator/deleteCalculatorDescription/${id}`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

// about us api
export const GetAllAboutUs = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "content/getAboutUs", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetAboutUsById = async (id) => {
	return await axios.get(
		BASE_URL + `content/getAboutUsById/${id}`
	);
};

export const UpdateAboutUs = async (id,formData) => {
	return await axios.put(
		BASE_URL + `content/updateAboutUs/${id}`,formData,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const DeleteAboutUs = async (id) => {
	return axios.delete(
		BASE_URL + `content/deleteAboutUs/${id}`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

//lets get started 
export const GetLetsGetSatrted = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "content/getLetsGetStarted", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetLetsGetSatrtedById = async (id) => {
	return await axios.get(
		BASE_URL + `content/getLetsGetStartedById/${id}`
	);
};

export const UpdateLetsGetSatrted = async (id,formData) => {
	return await axios.put(
		BASE_URL + `content/updateLetsGetStarted/${id}`,formData,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const DeleteLetsGetSatrted = async (id) => {
	return axios.delete(
		BASE_URL + `content/deleteLetsGetStarted/${id}`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

//mission vision
export const GetMissionVision = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "content/getMissionVision", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetMissionVisionById = async (id) => {
	return await axios.get(
		BASE_URL + `content/getMissionVisionById/${id}`
	);
};

export const UpdateMissionVision = async (id,formData) => {
	return await axios.put(
		BASE_URL + `content/updateMissionVision/${id}`,formData,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const DeleteMissionVision = async (id) => {
	return axios.delete(
		BASE_URL + `content/deleteMissionVision/${id}`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

//journey started
export const GetJourneyStarted = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "content/getJourney", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetJourneyStartedById = async (id) => {
	return await axios.get(
		BASE_URL + `content/getJourneyById/${id}`
	);
};

export const UpdateJourneyStarted = async (id,formData) => {
	return await axios.put(
		BASE_URL + `content/updateJourney/${id}`,formData,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const DeleteJourneyStarted = async (id) => {
	return axios.delete(
		BASE_URL + `content/deleteJourney/${id}`,
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

