import React, { useLayoutEffect } from "react";
import "./AdminProfile.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
// import { GetAdminProfile, EditAdminDetails } from "../../../Services/API/API";
import { toast } from "react-toastify";
import { EditAdminDetails, GetAdminProfile } from "../../Services/API/API";
import Header from "../../Header";

const EditAdminProfile = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [idData, setIdData] = React.useState({});

  //edit admin profile
  useLayoutEffect(() => {
    GetAdminProfile(id)
      .then((res) => {
        setIdData(res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [id]);

  //edit mentee
  const onChange = (e) => {
    setIdData({ ...idData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", idData?.name ? idData?.name : "");
    formData.append("email", idData?.email ? idData?.email : "");
    formData.append("country", idData?.country ? idData?.country : "");
    formData.append("address", idData?.address ? idData?.address : "");
    formData.append("company", idData?.company ? idData?.company : "");
    formData.append("phone_no", idData?.phone_no ? idData?.phone_no : "");
    formData.append(
      "company_site",
      idData?.company_site ? idData?.company_site : ""
    );

    console.log("formData", formData);
    EditAdminDetails(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Admin edited successfully!");
          navigate(-1);
        }
      })
      .catch((err) => {});
  };
  const navigateToDashboard = () => {
    navigate("/myprofile");
  };
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="ADMIN MANAGEMENT" subtitle="Edit Admin Profile" />
      </Box>
      <Form className="form">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={idData?.name}
            name="name"
            onChange={(e) => onChange(e)}
            className="form_control"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            defaultValue={idData?.email}
            name="email"
            disabled="true"
            className="form_control"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            defaultValue={idData?.country}
            name="country"
            onChange={(e) => onChange(e)}
            className="form_control"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Communication Address</Form.Label>
          <Form.Control
            type="text"
            defaultValue={idData?.address}
            name="address"
            onChange={(e) => onChange(e)}
            className="form_control"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Contact Phone</Form.Label>
          <Form.Control
            type="mobile"
            defaultValue={idData?.phone_no}
            name="phone_no"
            onChange={(e) => onChange(e)}
            className="form_control"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Company name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={idData?.company}
            name="company"
            onChange={(e) => onChange(e)}
            className="form_control"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Company Website</Form.Label>
          <Form.Control
            type="text"
            defaultValue={idData?.company_site}
            name="company_site"
            onChange={(e) => onChange(e)}
            placeholder="Enter email"
            className="form_control"
          />
        </Form.Group>
        <Button
          variant="primary"
          className="mx-3"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          onClick={(e) => {
            navigateToDashboard();
          }}
          variant="dark"
        >
          Go back
        </Button>
      </Form>
    </Box>
  );
};

export default EditAdminProfile;
