/** @format */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../Header";
import { DataGrid } from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { GetAllUserCertificate } from "../../Services/API/Course";

function CourseCertificate() {
	const [pageSize, setPageSize] = useState(50);
	const [dataGridHeight, setDataGridHeight] = useState("550px");
	const [adminData, setAdminData] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [userId, setUserId] = useState();
	const [userIdToNavigate, setUserIdToNavigate] = useState();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	//get all admin user api implementation
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllUserCertificate(
				localStorage.getItem("adminToken")
			);
			setAdminData(result.data.data);
			console.log("admins", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");

				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const navigateToEditAdmin = (data) => {
		navigate("/editAdminUser");
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = async (data) => {
		setAnchorEl(null);
	};

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: 90,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
		{
			field: "name",
			headerName: "Course Name",
			width: 150,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			valueGetter: (params) => {
				console.log("Row:", params.row);
				console.log("watchList_course:", params.row.watchList_course);
				if (params.row.watchList_course && params.row.watchList_course.name) {
					return params.row.watchList_course.name;
				} else {
					return "No Course";
				}
			},
		},

		{
			field: "email",
			headerName: "User Name",
			width: 350,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
			valueGetter: (params) =>
				params.row.user_watchlist.length > 0
					? params.row.user_watchlist[0].name
					: "No User",
		},
		{
			field: "course_progress",
			headerName: "Progress Of Course",
			width: 350,
			flex: 1,
			headerClassName: "custom-header",
			cellClassName: "custom-cell",
		},
	];

	return (
		<Box m="20px">
      {loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
			<>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Header
						title="COURSE CERTIFICATE"
						subtitle="View All Users Who Enrolled in Course"
					/>
				</Box>
				<div
					style={{
						height: dataGridHeight,
						width: "100%",
						marginTop: "20px",
					}}
				>
					<DataGrid
						rows={adminData}
						columns={columns}
						pageSize={pageSize}
						rowHeight={60}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						rowsPerPageOptions={[50, 100, 150]}
						experimentalFeatures={{ newEditingApi: true }}
						onSelectionModelChange={(id) => {
							const selectedIDs = new Set([id]);
							const selectedRowData = adminData.filter((row) =>
								selectedIDs.has(row.id.toString())
							);
							setUserId(selectedIDs);
						}}
					/>
				</div>
			</>
      		)}
		</Box>
	);
}

export default CourseCertificate;
