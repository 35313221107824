import React, { useState, useEffect, useStyles } from "react";
import axios from "axios";
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Grid,
  TextField,
  Container,
  InputLabel,
  Button,
} from "@mui/material";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button as MUIButton } from "@mui/material";
// import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import { BASE_URL, BASE_URL_IMAGE } from "../../Services/host";
import {
  CreateCourseIncludeAPI,
  CreateCourseSectionAPI,
} from "../../Services/API/API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Header from "../../Header";

const CourseDetails = () => {
  let { id } = useParams();
  const [selectedSectionLectures, setSelectedSectionLectures] = useState([]);
  const [courseData, setCourseData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedItemId, setEditedItemId] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [sectionHeading, setSectionHeading] = useState("");
  const [editedSectionHeading, setEditedSectionHeading] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [courseOutline, setCourseOutline] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [latestSectionId, setLatestSectionId] = useState(null);
  const [lectureCount, setLectureCount] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [lectureData, setLectureData] = useState({
    youtubeLink: "",
    contentType: "",
    contentDuration: "",
    contentName: "",
  });

  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    rotatedIcon: {
      transition: "transform 0.3s ease", // Replace 45deg with the desired rotation angle
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Check if the page has been reloaded already
    const isPageReloaded = localStorage.getItem("isPageReloaded");

    if (!isPageReloaded) {
      // Set the flag in local storage to prevent further reloads
      window.location.reload(true);
      localStorage.setItem("isPageReloaded", "true");
    } else {
      // Clear the flag to allow reloading on future navigation
      localStorage.removeItem("isPageReloaded");
    }
  }, []);

  useEffect(() => {
    if (id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `${localStorage.getItem("adminToken")}`,
        },
      };

      fetch(
        `${BASE_URL}course/getCourseById?course_id=${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 200 && data.data && data.data.course_section) {
            // Extract the sections from the API response
            const sections = data.data.course_section;
            setSections(sections);
          }
        })
        .catch((error) => console.error("Error fetching sections:", error));
    }
  }, []);

  const fetchLecturesForSection = async (courseId, sectionId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/course/getSectionContentBySectionId`,
        {
          course_id: courseId,
          section_id: sectionId,
        }
      );

      if (response.data.code === 200 && response.data.data) {
        setSelectedSectionLectures(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching lectures:", error);
    }
  };

  // Handle lecture data input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLectureData({ ...lectureData, [name]: value });
  };

  const handleAngle = () => {
    // Rotate the icon 45 degrees clockwise on each click
    setRotationAngle((prevAngle) => prevAngle + 180);
  };

  // Handle adding a new lecture to the selected section
  const handleAddLecture = () => {
    // Extract the data from the form inputs and other sources
    const courseId = sessionStorage.getItem("course_id");
    const sectionId = selectedSection.section_id;
    const { youtubeLink, contentType, contentDuration, contentName } =
      lectureData;

    // Create the request body
    const requestBody = {
      course_id: courseId,
      section_id: sectionId,
      content_data: youtubeLink, // Assuming youtubeLink is the content_data field
      content_type: contentType,
      content_duration: contentDuration,
      content_name: contentName,
    };

    // Make the API request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${localStorage.getItem("adminToken")}`,
      },
      body: JSON.stringify(requestBody),
    };

    fetch(
      "http://localhost:3000/api/v1/admin/createSectionDetails",
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Lecture added successfully!");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the API response if needed
        console.log("API Response:", data);
        // Reset the lecture data after adding.
        setLectureData({
          youtubeLink: "",
          contentType: "",
          contentDuration: "",
          contentName: "",
        });
        // Optionally, you might want to update the sections list with the newly added lecture
      })
      .catch((error) => console.error("Error adding lecture:", error));
  };

  // Style for the section list items
  const listItemStyle = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "8px",
    width: "80%", // Increase the width of the section list
    background: "#f0f0f0", // Change the background color
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: "18px",
  };

  // Style for the box that contains the "Add Lecture" form
  const lectureBoxStyle = {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    padding: "16px",
    marginBottom: "80px",
    width: "80%", // Increase the width of the box
    margin: 'auto'
  };

  // Style for the list container
  const listContainerStyle = {
    margin: "auto", // Center the list on the page
    width: "80%", // Adjust the width of the list container
    marginLeft: "187px",
  };
  const addLectureButtonStyle = {
    marginTop: "20px",
  };
  const viewAllCoursesButtonStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    padding: "12px 24px",
    backgroundColor: "#4caf50",
    color: "white",
    borderRadius: "4px",
    fontWeight: "bold",
    cursor: "pointer",
    zIndex: "999",
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {/* Display list of sections */}
      <List style={listContainerStyle}>
        {sections.map((section) => (
          <ListItem
            key={section.section_id}
            button
            onClick={() => {
              if (selectedSection === section) {
                setSelectedSection(null);
                setSelectedSectionLectures([]);
              } else {
                setSelectedSection(section);
                fetchLecturesForSection(id, section.section_id);
              }
              // Add your additional function call here
              handleAngle();
            }}
            style={{
              ...listItemStyle,
              background:
                selectedSection === section
                  ? "#f0f0f0"
                  : listItemStyle.background,
              fontWeight: selectedSection === section ? "bold" : "normal",
            }}
          >
            {section.section_heading}
            <IconButton>
              <ArrowDropDownIcon
                className={classes.rotatedIcon}
                style={{ transform: `rotate(${rotationAngle}deg)` }}
              />
            </IconButton>
          </ListItem>
        ))}
      </List>

      {selectedSection && (
        <Box style={lectureBoxStyle} >
          <Typography variant="h4" style={{marginBottom: '18px'}}>
            Lectures for {selectedSection.section_heading}
          </Typography>
          {/* Loop through selectedSectionLectures and display lecture information */}
          {selectedSectionLectures.map((lecture) => (
            <div key={lecture.id}>
              <Typography variant="h5">
                Lecture Name: {lecture.content_name}
              </Typography>
              <Typography variant="h6">
                Content Type: {lecture.content_type}
              </Typography>
              <Typography variant="h6">
                Duration: {lecture.content_duration}
              </Typography>
              <Typography variant="h6">
                YouTube Link:{" "}
                <a href={lecture.content_data}>{lecture.content_data}</a>
              </Typography>
              {/* You can add more fields as needed */}
              <Divider />
              <div style={{ marginTop: '20px' }}></div>
              {/* Display lecture information */}
              {/* lecture.youtubeLink, lecture.contentType, etc. */}
            </div>
          ))}

          {/* Add Lecture Form */}
          {/* ... your form to add lectures ... */}
        </Box>
      )}
      <Button style={viewAllCoursesButtonStyle}>View All Courses</Button>
    </Container>
  );
};

export default CourseDetails;
